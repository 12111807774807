export const APP = '/app';
export const AUTH = '/auth';

export const LANDING = '/';
export const DASHBOARD = `${APP}/dashboard`;
export const SIGN_UP = `${AUTH}/sign-up`;
export const SIGN_IN = `${AUTH}/sign-in`;
export const RESET_PASSWORD = `${AUTH}/reset-password`;
export const MESSAGING = `${APP}/messaging`;

export const SETTINGS = `${APP}/settings`;
export const USER_SETTINGS = `${SETTINGS}/usersettings`;

export const ADMIN_TASKS = `${SETTINGS}/admin-tasks`;
export const DOCUMENTATION = `${APP}/documentation`;