import { Backdrop, CircularProgress } from '@mui/material';
import { useEffect } from 'react';
import { useUserState } from '../../../context/UserContext';

const Loading = (props) => {
  const { authenticationInProcess } = useUserState();
  const {isLoading} = props;
  useEffect( ()=>{
      console.log('Authentication in process:', authenticationInProcess);
  },[authenticationInProcess]);
  useEffect ( ()=>{
    console.log('isLoading:', isLoading);
  },[]);
  return (
    <Backdrop
        open={authenticationInProcess || isLoading}
        //open={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 999 }}
    >
      <CircularProgress sx={{ color: 'secondary' }} />
    </Backdrop>
  );
};

export default Loading;
