import { createStyles, makeStyles } from '@material-ui/styles';

export  default makeStyles(theme => createStyles({
    dropZone: {
        height: '100px',
        fullWidth: 'true',
      },
      previewContainer: {
        container: 'true',
        width: '100%',
        height: '100%',
      },
      preview: {
        //width: '100%',
        //height: '100%',
        //item: 'true',
        xs: '12',
      },
      previewImg: {
        //height: '100%',
        //width: '100%',
      }, 
     
      paper: {
        paddingTop: `env(safe-area-inset-top)`,
        //paddingBottom: `env(safe-area-inset-bottom)`,
        paddingLeft: `env(safe-area-inset-left)`,
        paddingRight: `env(safe-area-inset-right)`,
      }
}));

//export default dropZoneStyle;