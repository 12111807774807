import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Autocomplete } from '@material-ui/lab';
import { spacing } from '@material-ui/system';
import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  Grid,
  Button,
  IconButton,
  Divider as MuiDivider,
  Toolbar,
  Tooltip,
  Typography,
  TextField
} from '@material-ui/core';

import {
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
  Label as LabelIcon
} from '@material-ui/icons';

import {  KeyboardDatePicker } from '@material-ui/pickers';
import { useUserState } from '../../context/UserContext';
import { AssessmentFilterProps } from '../../common/entityUtilities';




import { useUserListState } from '../../context/UserListContext';
import { getSorting, stableSort } from '../../common/tableUtilities';


const Divider = styled(MuiDivider)(spacing);
const Spacer = styled.div`
  flex: 1 1 100%;
`;

// Basically a duplicate of the VideoTableToolbar...
const AssessmentTableFilter = props => {

  const user = useUserState();
  const { allUsers } = useUserListState();
  const {handleFilter} = props;

  var sevendays = new Date();
  var nowdate = new Date();
  const [checkedState, setCheckedState] = useState({findWithNoItems:true, findWithNoReport:false, disableDates:false});

  const [searchStartDate, setSearchStartDate] = useState(sevendays.setDate(sevendays.getDate()-7));
  const [searchEndDate, setSearchEndDate] = useState(nowdate.setDate(nowdate.getDate()));
  const [searchDisabled, setSearchDisabled] = useState(false);
  
  const [currentSelectedParent, setCurrentSelectedParent] = useState(null);
  const [parentEmailForSearch, setParentEmailForSearch] = useState('');
  const [subjectIdForSearch, setSubjectIdForSearch] = useState('');


  useEffect(()=>{
    
    if(searchEndDate === null || searchStartDate === null ||
      isNaN(searchStartDate) || isNaN(searchEndDate)){
        console.log('Disabling Filter');
      setSearchDisabled(true);
      console.log('Search Start Date: ', searchStartDate);
      console.log('Search End Date: ', searchEndDate);
      return;
    }
    setSearchDisabled(false);
  },[searchStartDate,searchEndDate]);

  // Copied from AsmtAndUserControlComponent
  useEffect(()=>{
    console.log('The email;,', parentEmailForSearch);
    if(parentEmailForSearch === null){
      setCurrentSelectedParent(null);
      setSubjectIdForSearch(undefined);
      return;
    }

    const matchingParent = allUsers.find(user=>user.localEmail === parentEmailForSearch);
    console.log('The matching email ', matchingParent);
    if(matchingParent){
      
      setCurrentSelectedParent(matchingParent);
      if(matchingParent.subject){
        setSubjectIdForSearch(matchingParent.subject?.subjectId);
        console.log('subject id:', matchingParent.subject?.subjectId);
      }else{
        // parent has no subject adjust accordingly
        setSubjectIdForSearch('shouldnotmatchanything');
      }      
    }
  }, [parentEmailForSearch]);

  useEffect(()=>{
    if(subjectIdForSearch === null){
      setCurrentSelectedParent(null);
      setSubjectIdForSearch(undefined);
      return;
    }
    const matchingParent = allUsers.find(user=>user.subject?.subjectId === subjectIdForSearch);
    console.log('The parent for subject ', matchingParent);
    if(matchingParent){
      
      setCurrentSelectedParent(matchingParent);
      if(matchingParent.subject){
        setParentEmailForSearch(matchingParent.localEmail);
      }else{
        // parent has no subject adjust accordingly
        setParentEmailForSearch('noemail@found.here');
      }      
    }
  }, [subjectIdForSearch]);

  // This is the show button, which only sets a new filter, the table calls the actuall action
  const updateFilteredAsmtList = () => {
    const asmtSearchProps = {startDate:searchStartDate, endDate:searchEndDate, 
        includeAllDates:checkedState, parentEmail: parentEmailForSearch};
      handleFilter(asmtSearchProps);
    };

  //these make the search start/end date inclusive
  const setSearchStartDateStart = (date) => {
    if(!isNaN(date) && date !== null) {
      date.setHours(0, 0, 0, 0);
    }    
    setSearchStartDate(date);
  };

  const setSearchEndDateEnd = (date) => {
    if(!isNaN(date) && date !== null) {
      date.setHours(23, 59, 59, 999);
    }    
    setSearchEndDate(date);
  };
  const handleChange = (event) => {
    setCheckedState({ ...checkedState, [event.target.name]: event.target.checked });
    console.log('include all dates:',event.target.checked);
  };

  return (
    <div>
      <Toolbar>
        <Grid
          align="left"
          container 
          direction="column"
          justify="left"
          xs={12}
          sm={12}
          spacing={2}
        >
          <Grid
            align="left"
            container
            item
            justify="left"
            md={12} 
            spacing={1}
            xs={12}
            sm={8}
          >
            <Grid
              item
              xs={6}
            >
              <KeyboardDatePicker
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                disabled={checkedState['disableDates']}
                format="MM/dd/yyyy"
                label="From"
                margin="normal"
                onChange={setSearchStartDateStart}
                value={searchStartDate} 
              />
            </Grid>
            <Grid
              item
              xs={6}
            >
              <KeyboardDatePicker
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                disabled={checkedState['disableDates']}
                format="MM/dd/yyyy"
                label="To"
                margin="normal"
                onChange={setSearchEndDateEnd}
                value={searchEndDate}
              />
            </Grid>
            <Grid item xs={12} sm={6} justify="left">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkedState.disableDates}
                    name="disableDates"
                    onChange={handleChange}
                  />
                }
                label="Include all dates"
              />
          
            </Grid>
            {user.authLevel > 0 ? (

              <Grid            
                item
                xs={12} sm={8}
              >
                <Autocomplete
                  autoHighlight
                  blurOnSelect
                  getOptionLabel={(option)=> (option && option.localEmail)?option.localEmail:'unknown'}
                  // { // functionally equivalent, gads all this short hand
                  //   return (option && option.localEmail)?option.localEmail:'unknown';
                  // }}
                  handleHomeEndKeys
                  id="parent-email-selector"
                  onChange={(event, newValue) => {
                    console.log('Selected parent:',newValue);
                    if(newValue && newValue.localEmail){
                      setParentEmailForSearch(newValue.localEmail);
                    }else{
                      setParentEmailForSearch(null);
                    }
                  }}
                  options={stableSort(allUsers,getSorting('asc','localEmail'))}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Parent Email"
                      variant="outlined"
                    />
                  )}
                  selectOnFocus
                  style={{ width: 300 }}        
                  value={currentSelectedParent}
                />
              </Grid>): (<div></div>)}
            
          </Grid>

          <Grid
            alignItems="center"
            container 
            item
            md={4}
            spacing={10}
            xs={4}
          >            
            <Grid
              item
              md={12}
              xs={12}
            >
              <Button
                color="primary"
                disabled = {searchDisabled}
                onClick={updateFilteredAsmtList}
                size="small"
                variant="contained"
              >Filter Videos</Button>   
            </Grid>
          </Grid>

        </Grid>
      </Toolbar>
      <Divider my={6} />

    </div>
  );
};

export default AssessmentTableFilter;
