/* eslint-disable react/no-multi-comp */
import React, { useRef, useState, useEffect } from 'react';

import styled from 'styled-components';
import {Redirect} from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/messaging';

import { Autocomplete } from '@material-ui/lab';
import { spacing } from '@material-ui/system';
import {
  Card,
  CardContent,
  Button,
  IconButton,
  Grid,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Tooltip,
  TextField,
  Typography,
  Snackbar
} from '@material-ui/core';

import { getSorting, stableSort } from '../../common/tableUtilities';
import { addNotification } from '../../common/apiSetUtilities';

import { useUserListState } from '../../context/UserListContext';
import { useUserState } from '../../context/UserContext';
import { fbMessaging } from '../../firebaseConfig';
import { sendCloudMessageFromBackend } from '../../common/apiSetUtilities';
import { useTranslation } from 'react-i18next';
export const CloudMessaging = (props) => {

  const user = useUserState();
  const { allUsers } = useUserListState();
  const { t } = useTranslation();

  const { isAuthenticated,firebaseUser, authLevel } = useUserState();

  const [currentSelectedParent, setCurrentSelectedParent] = useState(null);
  const [parentEmailForSearch, setParentEmailForSearch] = useState('');
  const [messageTitle, setMessageTitle] = useState('Cribsy');
  const [messageBody, setMessageBody] = useState('');
  const [statusMessage, setStatusMessage] = useState(''); 
  const [isDestinationValid, setDestinationValid] = useState(false);

  useEffect( ()=>{
    if (currentSelectedParent?.deviceIds == '' || currentSelectedParent?.deviceIds == undefined){
      setStatusMessage(t('status1',{ns:'messaging'}));
      setDestinationValid(false);
    }
    else{
      setStatusMessage(t('status2',{ns:'messaging'}));
      setDestinationValid(true);
    }
  },[currentSelectedParent]);
  // Initialize Firebase Cloud Messaging and get a reference to the service

  const sendCloudMessage = async ()=> {
    const ids = currentSelectedParent?.deviceIds;
    console.log('Will send to the following deviceIds:', ids);

    const message = {
      data: {
        title: messageTitle,
        body: messageBody
      },
      notification: {
      title: messageTitle,
      body: messageBody
      }
    };

    const result = await sendCloudMessageFromBackend(firebaseUser, JSON.stringify(message), ids) ;

        // add this message as a notification to the user's data on the backend
        // next time user opens the app, FOR SURE, this notification will be present
    console.log('Result from sendCloudMessageFromBackend:', result);
    if (result) {
        console.log('Adding notification to userdata on mongo');
        addNotification(firebaseUser, currentSelectedParent._id, messageTitle, messageBody);
    }
    

  };

  if (user.authLevel < 10){
    return (
      <Typography variant="h5">
        You don&apos;t have permission to view this page.
      </Typography>
    );
  }
  return (
    <div>
    <Card mb={6}>
      <CardContent pb={0}>
        <Typography
          gutterBottom
          variant="h5"
        >
          {t('title',{ns:'messaging'})}
        </Typography>

        <Grid
            align="left"
            container
            item
            justify="left"
            spacing={2}
            xs={12}
            sm={12}
            md={8}
          >

            <Grid item xs={12} sm={12} justify="left">
              <Typography variant='body1' color='secondary'>
                {
                    t('selectparent',{ns:'messaging'})
                }
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} justify="left">
              
            <Autocomplete
                  autoHighlight
                  blurOnSelect
                  getOptionLabel={(option)=> (option && option.localEmail)?option.localEmail:'unknown'}
                  // { // functionally equivalent, gads all this short hand
                  //   return (option && option.localEmail)?option.localEmail:'unknown';
                  // }}
                  handleHomeEndKeys
                  id="parent-email-selector"
                  onChange={(event, newValue) => {
                    console.log('Selected parent:',newValue);
                    setCurrentSelectedParent(newValue);
                    if(newValue && newValue.localEmail){
                      setParentEmailForSearch(newValue.localEmail);
                    }else{
                      setParentEmailForSearch(null);
                    }
                  }}
                  options={stableSort(allUsers,getSorting('asc','localEmail'))}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('parentemail',{ns:'messaging'})}
                      variant="outlined"
                    />
                  )}
                  selectOnFocus
                  style={{ width: 300 }}        
                  value={currentSelectedParent}
                />
          
            </Grid>

            <Grid item xs={8} sm={8} justify="left">
              <Typography variant='body1'>
                {t('firstname',{ns:'messaging'})} {currentSelectedParent?.firstname}
              </Typography>
            </Grid>

            <Grid item xs={8} sm={8} justify="left">
              <Typography variant='body1'>
                {t('lastname',{ns:'messaging'})} {currentSelectedParent?.lastname}
              </Typography>
            </Grid>

            <Grid item xs={6} sm={6}>
              <Typography variant='body1' color='secondary'>
              {statusMessage}
              </Typography>
            </Grid>

        </Grid>
    </CardContent>
    </Card>

    <Card mb={6}>
      <CardContent pb={0}>
        <Grid container spacing={2}>
          <Grid item xs={8} sm={8}>
            <Typography
              gutterBottom
              variant="h5"
            >
              {t('format',{ns:'messaging'})}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid
              item
              md={8}
              xs={8}
              sm={8}
              
            >
              <TextField
                id="title"
                label={t('msgtitle',{ns:'messaging'})}
                m={2}
                onChange={(e)=>setMessageTitle(e.target.value)}
                value={messageTitle}
                variant="outlined"
              />
            </Grid>

          <Grid
              item
              md={8}
              xs={8}
              sm={8}
            >
              <TextField fullWidth
                id="body"
                label={t('body',{ns:'messaging'})}
                m={2}
                onChange={(e)=>setMessageBody(e.target.value)}
                value={messageBody}
                variant="outlined"
              />
            </Grid>
        <Grid 
              item
              md={6}
              xs={8}
              sm={8}>

          <Button
                color="primary"
                onClick={sendCloudMessage}
                size="small"
                variant="contained"
                disabled = {!isDestinationValid}
          >
            {t('send',{ns:'messaging'})}
          </Button>  
        </Grid>

        </Grid>
      </CardContent>

    </Card>
    </div>
  );
};

