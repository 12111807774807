/* eslint-disable react/jsx-key */
import React, { useEffect, useState} from 'react';

import {
  Badge,
  Button,
  Grid,
  Divider,
  IconButton as MuiIconButton,
  ListItemText,
  List,
  ListItemIcon

} from '@material-ui/core';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import {
  Bell,
  AlertCircle
} from 'react-feather';

import styled from 'styled-components';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { useUserState } from '../../../context/UserContext';
import { getNotifications } from '../../../common/apiGetUtilities';
import { deleteNotification } from '../../../common/apiDelUtilities';
import { Alert } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Indicator = styled(Badge)`
  .MuiBadge-badge {
    background: orange;
    color: white;
  }
`;
// background: ${props => props.theme.header.indicator.background};
// color: ${props => props.theme.palette.common.white};
const NotificationButton = (props) =>{
  const user = useUserState();
  const {t} = useTranslation();
  
  const { isAuthenticated,firebaseUser, authLevel } = useUserState();
  const [notifications, setNotifications] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
 
  const updateNotifications = async () => {
    console.log('Getnotifications for user');
    const notifications = await getNotifications(firebaseUser);
    setNotifications(notifications);
  };
  useEffect(() =>{
    // This causes unmount problesm aka a leak, should be fixed in later iterations.
    updateNotifications();
  },[]);



  const handleClick = event => {
    updateNotifications();
    console.log('Notifications click:', notifications);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {Promise.all( 
    //delete all the notifications for this emmauser
    notifications.map ( async (notification) => {
      console.log('Deleting Notification:', notification);
      try {
        await deleteNotification(firebaseUser, notification._id);
      } catch(err) { console.log('Err in deleting notification:', err);}
      finally {
        console.log('Updating notifications');
        updateNotifications();
      }
    })
  );
  };


  const notificationUI = notifications.map((notification) => 
    <Grid container align='center'>
    <Grid item xs={10}>
    <ListItemText key={notification.notificationId}
      primary={notification.text}
      >
    </ListItemText>
    </Grid>
    <Grid item xs={10}>
    <Divider variant="inset" component="li" />
    </Grid>
    </Grid>);
  

  
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  // dlp doesnt not understant the error
  return [

    <IconButton
      color="inherit"
      onClick={handleClick}
    >
      <Indicator badgeContent={notifications.length}>
        <Bell />
      </Indicator>
    </IconButton>,

    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      id={id}
      onClose={handleClose}
      open={open}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      {notifications.length == 0 ? (<Typography variant='h6' color='secondary'>{t('noalerts',{ns:'header'})}</Typography>): 
        (
          <div>
            <Typography variant='h5'color='secondary'>Alerts</Typography>
            <Divider></Divider>
            <Typography variant="body1">
              <List alignItems='center'>
                {notificationUI}
              </List>
            </Typography>
            <Button variant='contained' color='primary' startIcon={<DeleteOutline/>} onClick={handleDelete}>Clear</Button>
          </div>
        )}
    </Popover>            
  ];
};

export default NotificationButton;
