import React, { useRef, createRef, useState, useEffect } from 'react';

import styled from 'styled-components';
import {Redirect} from 'react-router-dom';

import { spacing } from '@material-ui/system';
import {
  Card as MuiCard,
  CardContent as MuiCardContent,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
  Snackbar
} from '@material-ui/core';

import {
  Delete,
  VideocamTwoTone,
  CloudDownload,
  AddCircleOutlined
} from '@material-ui/icons';

import { showAlert } from '../../common/utilities';
import AssessmentTwoToneIcon from '@mui/icons-material/AssessmentTwoTone';

import AssessmentTableHead from './AssessmentTableHead';
import { useUserState, refreshToken} from '../../context/UserContext';
import { stableSort, getSorting } from '../../common/tableUtilities';
import VideoDetails from '../../dialogs/VideoDetails/VideoDetails';
import ReportMetricsGrid from '../../dialogs/VideoDetails/components/ReportMetricsGrid.js';
import AssessmentTableFilter from './AssessmentTableFilter';

import { useTranslation, withTranslation } from 'react-i18next';

const CardContent = styled(MuiCardContent)(spacing);
const Card = styled(MuiCard)(spacing);
const Paper = styled(MuiPaper)(spacing);
const TableWrapper = styled.div`
overflow-y: auto;
max-width: calc(100vw - 12px);
`;

const AssessmentInfoTable = (props) => {

const {t, i18n} = useTranslation();const user = useUserState();
const {allAssessments, handleFilter} = props;

const [order, setOrder] = useState('desc');
const [orderBy, setOrderBy] = useState('parent');
const [page, setPage] = useState(0);
const [rowsPerPage, setRowsPerPage] = useState(5); // 5 entries per page by default
const detailsPageElement = createRef(); //useRef();
const [openReport, setOpenReport] = useState(false);
const [currentAssessment, setCurrentAssessment] = useState(null);

  const emptyRows =
  rowsPerPage - Math.min(rowsPerPage, allAssessments?.length - page * rowsPerPage);

  const handleRequestSort = (event, property) => {
    const orderByProp = property;
    let orderProp = 'desc';

    if (orderBy === property && order === 'desc') {
      orderProp = 'asc';
    }
    setOrder(orderProp);
    setOrderBy(orderByProp);
  };

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
  };

  const handleVideoDetails = async(asmt) => {
    detailsPageElement.current.setVideoData(asmt, user);
    await refreshToken(user);
    detailsPageElement.current.handleClickOpen();

  };

  const reportDialogComplete = ()=> {
    setOpenReport(false);
    setCurrentAssessment(null);
  }

  const handleReportDetails = async (asmt) => {
    setCurrentAssessment(asmt);
    if(asmt?.notes){
        setOpenReport(true);
    }
    else{
        await showAlert('Report', 'There is no report for this video yet!', 'Ok');          
    }

  }


    if (user.authLevel < 0){
    return (
        <Typography variant="h5">
        Hmmm...you may not yet have content here.
        </Typography>
    );
    }
    return (
        <Paper>
        <VideoDetails ref={detailsPageElement} user={user}/>
        {user.authLevel > 0 && 
          <AssessmentTableFilter
            handleFilter={handleFilter}/>}
        <TableWrapper>
            <Table aria-labelledby="tableTitle">
                <AssessmentTableHead
                // numSelected={selectedVideoIds.length}
                onRequestSort={handleRequestSort} 
                order={order}
                orderBy={orderBy}
                rowCount={allAssessments.length}
                />
                <TableBody>
                {allAssessments &&

                stableSort(allAssessments, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(assessment => {
                    const idToUse = assessment._id;
                    return (
                        <TableRow 
                            hover
                            key={idToUse}
                            onClick = {()=>{console.log('Clicked:', idToUse);}}
                            // selected={isSelected}
                            tabIndex={-1}
                        >
                            <TableCell 
                            align="center"
                            style={{width:'10%'}}
                            >
                                {assessment?.date &&
                                (i18n.language == 'es' || i18n.language == 'es-ES') ? 
                                (
                                  new Date(assessment.date).toLocaleDateString('es-ES', { year: 'numeric', month: '2-digit', day: '2-digit' })
                                ): 
                                (
                                  new Date(assessment.date).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' })
                                )
                                }
                                
                            </TableCell>

                            <TableCell 
                            align="left"
                            style={{width:'10%'}}
                            >
                            {assessment?.status == "complete" || assessment?.resulturl? (
                                <div> {t('completed',{ns:'asmttablehead'})} </div>
                            ): ( <div>
                                {assessment?.percentageCompleted  ? 
                                (
                                    <div>
                                        <LinearProgress 
                                        variant="determinate"
                                        value={assessment.percentageCompleted}/>
                                    </div>
                                ):
                                (
                                    <div>
                                        {t('inq',{ns:'asmttablehead'})}
                                    </div>
                                
                                )
                                }
                                </div>
                            )
                            }
                            </TableCell>
                            
                            <TableCell 
                            align="left"
                            style={{width:'10%'}}
                            >
                                <IconButton color="primary" 
                                    onClick={()=>{handleReportDetails(assessment)}}>
                                    <AssessmentTwoToneIcon/>
                                </IconButton>
                            </TableCell>
                            
                            <TableCell 
                            align="left"
                            style={{width:'10%'}}
                            >
                                <IconButton color="primary" onClick={()=>{handleVideoDetails(assessment)}}>
                                    <VideocamTwoTone/>
                                </IconButton>
                            </TableCell>

                        </TableRow>
                    );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 49 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
                </TableBody>
            </Table>
        </TableWrapper>

        <TablePagination
          backIconButtonProps={{
            'aria-label': 'Previous Page'
          }}
          component="div"
          count={allAssessments.length}
          nextIconButtonProps={{
            'aria-label': 'Next Page'
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          page={page}
          rowsPerPage={rowsPerPage}
          labelRowsPerPage={t('rowsperpage',{ns:'asmttablehead'})}
          rowsPerPageOptions={[5, 10, 25]}
        />
        <ReportMetricsGrid isOpen={openReport} assessment={currentAssessment} dialogComplete={reportDialogComplete}/> 
        </Paper>
    );
};

export default AssessmentInfoTable;