import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { UserProvider } from './context/UserContext';
import { UserListProvider } from './context/UserListContext';
import { LayoutProvider } from './context/LayoutContext';
import {ThemeProvider} from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { useQuery, QueryClient, QueryClientProvider } from 'react-query';

import { CssBaseline } from '@material-ui/core';
import { SharedSnackbarProvider } from './context/SnackbarContext';
import Themes from './themes';
import { SubjectProvider } from './context/SubjectContext';
import { I18nextProvider } from 'react-i18next';

import i18nInstance from './i18n';


// const root = ReactDOM.createRoot(document.getElementById('root'));

const queryClient = new QueryClient();
ReactDOM.render(
  //<React.StrictMode> //strictmode renders components twice in dev mode!!!
  <I18nextProvider i18n={i18nInstance} defaultNS={'translation'}
  //i18n is the i18n instance created in the i18n.js file. 
  // This is used to pass the i18n instance to the I18nextProvider component. 
  // This allows the I18nextProvider component to use the i18n instance to translate the text.
  >
    <LayoutProvider>
      <UserProvider>
        <SubjectProvider>
        <UserListProvider>
          <ThemeProvider theme={Themes.default}>
            <CssBaseline />
            <SharedSnackbarProvider>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <QueryClientProvider client={queryClient}>
                <App />
              </QueryClientProvider>
              </MuiPickersUtilsProvider>
            </SharedSnackbarProvider>
          </ThemeProvider>
        </UserListProvider>
        </SubjectProvider>
      </UserProvider>
    </LayoutProvider>
  </I18nextProvider>,
    document.getElementById('root'),
  //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
