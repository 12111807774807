import React from 'react';
import { useState, useEffect } from 'react';

import classNames from 'classnames';
import { makeStyles } from '@material-ui/styles';
import { 
  Typography, 
  AppBar,
  Toolbar,
  Link,
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  Grid,
  List,
  ListItemText,
  ListItem as MuiListItem,
  DialogTitle,
  Menu,
  MenuItem,
  Paper,
  Select
} from '@material-ui/core';


import { IconButton } from '@mui/material';

import { useTranslation } from 'react-i18next'; 
import TOS from '../../pages/documents/TOS';
import languages from '../../translations/lngs.json';

import { Globe } from 'react-feather';
import styled from 'styled-components';

import useStyles from './styles';
import { Hidden } from '@material-ui/core';

const Wrapper = styled.div`
  padding: 2px
    4px;
  background: white;
  position: relative;
`;
// padding: ${props => props.theme.spacing(1) / 4}px
// ${props => props.theme.spacing(4)}px;
// background: ${props => props.theme.palette.common.white};
const ListItem = styled(MuiListItem)`
  display: inline-block;
  width: auto;
  padding-left: 2px;
  padding-right: 2px;

  &,
  &:hover,
  &:active {
    color: #000;
  }
`;
// padding-left: ${props => props.theme.spacing(2)}px;
// padding-right: ${props => props.theme.spacing(2)}px;
const Footer = () =>{
  const [tosOpen, setTOSOpen] = useState(false);
  const {t, i18n} = useTranslation(); 
  const [language, setLanguage] = useState( localStorage.getItem('language')===null ? 'en' : localStorage.getItem('language') );
  // the above line should take care of the case where the language is not set in local storage, like the first time the app is run

  const [languageMenu, setLanguageMenu] = useState(null);


  const classes = useStyles();


  useEffect(()=>{
    if(localStorage.getItem('language')===null){
      localStorage.setItem('language', 'en');
      localStorage.setItem('i18nextLng', 'en');
      console.log('[Footer]: Setting language to en');
      setLanguage('en');
    }
    else{
      setLanguage(localStorage.getItem('language'));
    }
  },[]);

  const changeLanguage = (lng) =>{    
    console.log('Changing language to: ', lng);
    localStorage.setItem('language', lng);
    localStorage.setItem('i18nextLng', lng);
    i18n.changeLanguage(lng);
    setLanguage(lng);
  }; 


  const handleClose = ()=>{
    setTOSOpen(false);
  }

  return (
    <Wrapper style={{width:'100%'}}>

        <Grid container item  spacing={2} justifyContent="center" alignItems='center'>
      
          <Grid item xs={6} sm={4} md={4}>
        

          <IconButton
            aria-controls="profile-menu"
            aria-haspopup="true"
            className={classes.headerMenuButton}
            color="inherit"
            onClick={e => setLanguageMenu(e.currentTarget)}
          >
            <Globe classes={{ root: classes.headerIcon }} />
            {/* <Typography weight="medium">{t("languages",{ns:"footer"})}</Typography> */}
          </IconButton>

          {
            <Select defaultValue={language}>
              {Object.keys(languages).map( (lng) => {
                return(
                <MenuItem value={lng.toString()} 
                selected={i18n.resolvedLanguage===lng.toString()}
                onClick={()=>{changeLanguage(lng.toString())}}>
                {languages[lng].nativeName}
                </MenuItem>
                )
              })}
            </Select>
          
          }



          </Grid>

          {/* how to make the grid item disappear on small screens */}
          <Hidden xsDown>
            <Grid item md={4} sm={4} xs={1}>
            <Typography>© {t("copyright",{ns:"footer"})}</Typography>
            </Grid>
          </Hidden>
          

          <Grid item xs={6} sm={4}>
            <Typography  color="secondary" onClick=
              {()=>{
              setTOSOpen(true);}}
            > 
            {t("terms",{ns:"footer"})} 
            </Typography>
          </Grid>
        </Grid>
    
      <TOS
        isOpen={tosOpen}
        handleClose={handleClose}
      />
    
    </Wrapper>
  );
};

export default Footer;
