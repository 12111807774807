import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    appbar: {
        paddingTop: `env(safe-area-inset-top)`,
        //paddingBottom: `env(safe-area-inset-bottom)`,
        paddingLeft: `env(safe-area-inset-left)`,
        paddingRight: `env(safe-area-inset-right)`,
    },

    paper: {
        paddingTop: `env(safe-area-inset-top)`,
        paddingBottom: `env(safe-area-inset-bottom)`,
        paddingLeft: `env(safe-area-inset-left)`,
        paddingRight: `env(safe-area-inset-right)`,
    }
}))