import React from 'react';
import axios from 'axios';

import {
  getDerivedDataVideo, 
  getActivitiesForRecordingDevices
} from '../../common/apiGetUtilities';
import {
  Tabs,
  Tab,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  Divider,
  Paper,
  AppBar,
  Toolbar,
  Tooltip,
  IconButton,
  Typography
} from '@material-ui/core';

import {
  ArrowBack,
  Close
} from '@material-ui/icons';

import { useUserState} from '../../context/UserContext';

import VideoDetailsTabPanel from './components/VideoDetailsTabPanel';

import { withTranslation } from 'react-i18next';
import i18next, {t} from 'i18next';

class VideoDetails extends React.Component {
    state = {
      open: false,
      fullWidth: true,
      maxWidth: 'sm',
      thumbnailUrl: {},
      timestamp:'',
      videoData: {},
      videoOverlayData: {},
      videoOverlayDataOP: {},
      videoOverlayDataActivity: '',
      motorRisk: {},
      motorRiskP: {},
      markers: [],
      tabIndexValue: 0,
      isPlaying: false,
      selected:0
    };


    //q: how to use hooks in react class component?
    

    handleDerivedData =  (data) => {
      if (data && data.length > 0){
        for (let i=0; i < data.length; i++){
          const dd = data[i];
          const props = dd.additionalProps;
          if (props.name === 'keypoints_video'){
            this.setState({videoOverlayData: dd.url});
            console.log('DD url for keypoints_video:', dd.url);
          }
          else if (props.name === 'keypoints_video_openpose') {
            this.setState({videoOverlayDataOP: dd.url});
            console.log('DD url for keypoints_video_openpose:', dd.url);
          }
          else if (props.name === 'activities_video'){
            this.setState({videoOverlayDataActivity: dd.url});
            console.log('DD url for activities_video:', dd.url);
          }
          else if (props.name === 'motor_risk'){
            // dd.url has two components: first one is video, second is json containing bayesian surprise data      
            const urlArray = dd.url ? dd.url.split('|') : '';
            console.log('video overlay url:', urlArray[0]);
            this.setState({videoOverlayDataOP: urlArray[0]});
            if (urlArray && urlArray.length > 1){
              this.getDataFromUrl(urlArray[1]);
              
            }
          }
        }
      }
    };

    handleClickOpen = () => {
        this.setState({ open: true }, function(){
            console.log('dialog open state',this.state.open);
          });
    };


    handleClose = () => {
        this.setState({ open: false });
    };
    
    handleMaxWidthChange = event => {
        this.setState({ maxWidth: event.target.value });
    };
    
    handleFullWidthChange = event => {
        this.setState({ fullWidth: event.target.checked });
    };


    handleTabChange = (event, newValue) => {
        this.setState({selected:newValue}, function(){
          console.log('New tab value:',this.state.selected);
        });
    };
  
    a11yProps(index) {
        return {
          id: `tab-${index}`,
          'aria-controls': `tabpanel-${index}`
        };
    };

    getDataFromUrl = async (url) => {
      
      console.log('JSON metrics url:', url.trim());
    //   const res = await fetch(url)
    //   .then(res => res.json())
    //   .then ( (out)=> { console.log('Json output:', out); return out;})
    //   .catch(err=> {console.log('Error fetching:', err);})

    //   console.log('fetched from url:', res);
    //   console.log('head turn:', res.head_turn_center);
     
    try{

        const {uid, bearerToken } = this.props.user;
        console.log('UID:', uid);
        axios.defaults.headers.common = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearerToken}`,
            'uid': uid,
            'Access-Control-Allow-Origin': '*'
        };
    
        const responseData = await axios.get(url.trim());
        console.log('Response from json url:', responseData);


        this.setState({motorRisk: responseData?.data});
    } catch(err){
        console.log('Fetch error:', err);
    }
    }

    setVideoData = async (data, userInfo) => {
        console.log('In VideoDetails->');
        this.setState({videoData:data});
        console.log('MotorRisk from asmt object:', data.notes);
        this.setState({motorRisk:data.notes});
        this.setState({timestamp: new Date(data.date).toLocaleString()});

            const urlArray = data.resulturl ? data.resulturl.split('|') : '';
            console.log('urlArray:', urlArray);
            console.log('video overlay url:', urlArray[0]);
            this.setState({videoOverlayDataOP: urlArray[0]});
            // if (urlArray && urlArray.length > 1){
            //     this.getDataFromUrl(urlArray[1]);
                    
            // }
      
    }

    handleBackdropClick = () => {
      console.log('Backdrop clicked');
    };

    handleClickOpen = () => {
      this.setState({ open: true }, function(){
        console.log('dialog open state',this.state.open);
      });
    };
  
    handleClose = () => {
      this.setState({ open: false });
    };
  
    handleMaxWidthChange = event => {
      this.setState({ maxWidth: event.target.value });
    };
  
    handleFullWidthChange = event => {
      this.setState({ fullWidth: event.target.checked });
    };

    handleTabChange = (event, newValue) => {
      this.setState({selected:newValue}, function(){
        console.log('New tab value:',this.state.selected);
      });
    };

    a11yProps(index) {
      return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`
      };
    }
      


    render() {

    const {t, i18n} = this.props;
      return (  
        <Paper mt={4}>
          <React.Fragment>               
            <Dialog
              aria-labelledby="max-width-dialog-title"
              fullWidth={this.state.fullWidth}
              maxWidth={this.state.maxWidth}
              onClose={this.handleClose}
              open={this.state.open}
              onBackdropClick={this.handleBackdropClick}
            >
              <Toolbar sx={{ justifyContent: "space-between" }}>
                <Tooltip title="Back to Video List">
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    edge="start"
                    onClick={this.handleClose}
                    variant="outlined"
                  >
                    <Close                
                      fontSize="large"
                      color='secondary'
                    />
                  </IconButton>
                </Tooltip>
                <Typography variant="h4" align='center'>{t('title',{ns:'videodetails'})} </Typography>
                
              </Toolbar>
              
              {this.props.user.authLevel > 0 &&
                <Paper>
                  <Tabs 
                    aria-label="Video"
                    centered
                    indicatorColor="primary" 
                    onChange={this.handleTabChange} 
                    value={this.state.selected}
                  >
                    <Tab
                      label="Home video"
                      {...this.a11yProps(0)}
                    />
                 
                    <Tab
                      label="Metrics"
                      {...this.a11yProps(1)}
                    />
                  
                  
                  </Tabs>
                </Paper>              
              }    
              {this.props.user.authLevel == 0 &&
                <Paper>
                  <Tabs 
                    aria-label="Video"
                    centered
                    indicatorColor="primary" 
                    onChange={this.handleTabChange} 
                    value={this.state.selected}
                  >
                    <Tab
                      label={t('videotabtitle',{ns:'videodetails'})}
                      {...this.a11yProps(0)}
                    />
                    <Tab
                      label={t('metricstabtitle',{ns:'videodetails'})}
                      {...this.a11yProps(1)}
                    />
                  </Tabs>
                </Paper>
              } 
              <Divider my={6} />
              <VideoDetailsTabPanel
                index={0}
                value={this.state.selected}
                videoData={this.state.videoData}
                timestamp={this.state.timestamp}
              />
             
              <VideoDetailsTabPanel
                index={1}
                value={this.state.selected} 
                videoData={this.state.videoData}
                videoOverlayDataOP={this.state.videoOverlayDataOP}
                motorRisk = {this.state.motorRisk}
              />
              <VideoDetailsTabPanel
                index={2}
                markers={this.state.markers} 
                value={this.state.selected}
                videoData={this.state.videoData}
                videoOverlayDataActivity={this.state.videoOverlayDataActivity}
              />
              <DialogActions>
                <Button
                  color="primary"
                  onClick={this.handleClose}
                >
                      {t('close',{ns:'videodetails'})}
                </Button>
              </DialogActions>
              <Card>
                <CardContent />
              </Card>
            </Dialog>
          </React.Fragment>
        </Paper>
      );
    }
}

export default withTranslation('videodetails', {withRef:true})(VideoDetails);