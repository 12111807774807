
import React, {useContext, useEffect, useState, useRef} from 'react';
import {Redirect, useHistory} from 'react-router-dom';

import {
    Grid,
    Divider,
    Button,
    Card, 
    CardContent,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails
  } from '@material-ui/core';


import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import { spacing } from '@material-ui/system';
import SocketIOContext, {initializeSocket} from '../../context/SocketIOContext';
import { getSubjectFromParentEmail } from '../../common/apiGetUtilities';
import { useTranslation } from 'react-i18next'; 

import { green, blue, orange, yellow, blueGrey, lightBlue } from '@material-ui/core/colors';
import {
  Activity,
  Info,
  Smile,
  Video,
  UserPlus,
  Film
} from 'react-feather';
import * as RouteNames from '../../common/routeNames';

import Actions from './components/Actions';
import { SubjectInfo } from '../../dialogs/SubjectInfoDialog';
import Stats from './components/Stats';
import useStyles from './styles';
import { useUserState } from '../../context/UserContext';
import * as AppRoutes from '../../common/routeNames';
import { useQuery, QueryClient, QueryClientProvider } from 'react-query';
import { DeviceProps, emptyDeviceState } from '../../common/entityUtilities';
import NewAssessmentDialog from '../../dialogs/NewAssessmentDialog/NewAssessmentDialog';
import { UserProps } from '../../common/entityUtilities';
import { useSubjectState } from '../../context/SubjectContext';
import { getAllAssesments, getFilteredAssesments, getSampleAssesments } from '../../common/apiGetUtilities';
import AssessmentInfoTable from '../../components/Assessments/AssessmentInfoTable';
  
const currentDate = new Date();

function Dashboard(props) {

    const history = useHistory();
    const {t, i18n} = useTranslation(); 
    const classes = useStyles();
    const [redirect, setRedirect] = useState(null);
    const kiwiUser = useUserState();
    const subject = useSubjectState();
    const [isAuthenticated, setAuthenticated] = useState(false);
    const [devices, _setDevices] = useState([]);
    const [authLevel, setAuthLevel] = useState(-1);
    const [assessments, _setAssessments] = useState([]);
    const [sampleAssessments, setSampleAssessments] = useState([]);
    const registerPage = AppRoutes.USER_SETTINGS;
    const socket = initializeSocket();
    const devicesRef = useRef(devices);
    const [expandAccordion, setExpanded] = useState(false);
    const [expandSampleAccordion, setSampleExpanded] = useState(false);
    const assessmentsRef = useRef(assessments);
    const sampleAssessmentsRef = useRef(sampleAssessments);
    const userRef = useRef(kiwiUser);
    const [subjectInfoOpen, setSubjectInfoOpen] = useState(false);
    const [asmtToUpdate, setAsmtToUpdate] = useState();
    const [asmtDialogOpen, setAsmtDialogOpen] = useState(false);
    const [parentId, setParentId] = useState(null);
    const [uploadEnabled, setUploadEnabled] = useState(false);
    const [dobButtonText, setDobButtonText] = useState(t('dobbutton',{ns:'dashboard'}));
    const initialCardDescription = t('step1para',{ns:'dashboard'});
    const updatedCardDescription = t('step1alt',{ns:'dashboard'}); 
    const [dobCardDescription, setDobCardDescription] = useState(initialCardDescription);


    const loginPage = AppRoutes.SIGN_IN;

    const introCardTitle = "Introduction";
    const introCardDescription = "Cribsy will analyze your infant's movements and deliver developmental metrics.\
        All you need is a smartphone camera to capture 3 minutes of your baby in the crib.\
        This works best for infants up to 15 weeks after birth. Make sure you check the Terms of Service at the bottom of this page.\
        Click button below to learn how best to make a crib video.";
    const introButtonText = "How to make crib videos";
    const introButtonCallback = (()=>{
        showTutorialVideo();});

    const dobCardTitle =  t('step1',{ns:'dashboard'});
    const dobButtonCallback = (()=>{
        setSubjectInfoOpen(true)});


    const videoCardTitle = t('step2',{ns:'dashboard'});
    const videoCardDescription = t('step2para',{ns:'dashboard'});
    const videoButtonText = t('uploadbutton',{ns:'dashboard'});
    const videoButtonCallback = (()=>{
        setAsmtDialogOpen(true);});

    useEffect( ()=> {
        setAuthLevel(kiwiUser?.authLevel)
    }, []);


    useEffect(()=>{
        socket.on("message", handleSocketMessage);
    },[]);

    useEffect(()=>{
        //console.log('[Dashboard] CribsyUser:', kiwiUser);
        setAuthenticated(kiwiUser?.isAuthenticated);
        //console.log('Subject:', subject);
        if(subject?.parentid != undefined && subject?.parentid != ''){
            console.log('Enabling upload button');
            setUploadEnabled(true);
        }
        if(subject?.dob && subject?.duedate && subject?.parentid !== ''){
            console.log('Subject has both DOB and DUEDATE!');
            setDobButtonText(t('dobbuttonalt',{ns:'dashboard'}));
            setDobCardDescription(updatedCardDescription);
        }
        setParentId(kiwiUser?._id);
        userRef.current = kiwiUser;
    },[kiwiUser, subject]);

    useEffect (()=>{
        if (!kiwiUser) { return;}
        console.log('Get asmts for:', kiwiUser?.firebaseUser?.uid);
        const getasmts = async ()=> {
            const asmts = await getAllAssesments(kiwiUser?.firebaseUser);
            console.log('Asmts:', asmts?.length);
            assessmentsRef.current = asmts;
            _setAssessments(asmts);

            const samples = await getSampleAssesments(kiwiUser?.firebaseUser);
            console.log('Sample asmts:', samples?.length);
            if (samples){
                setSampleAssessments(samples.slice(0,2)); // use only two sample videos
            }
            //console.log('Subject:', subject);
            if(subject?.dob && subject?.duedate && subject?.parentid !== ''){
                console.log('Subject has both DOB and DUEDATE!');
                setDobButtonText(t('dobbuttonalt',{ns:'dashboard'}));
                setDobCardDescription(updatedCardDescription);
            }
        }
        if(kiwiUser){
            getasmts();
        }
    },[kiwiUser]);

    const showTutorialVideo = () => {
        const url = (i18n.language=='en') ? 'https://youtu.be/jKOm49wJ9Aw': 
        'https://youtu.be/81fX677PoHM' ;
        window.open(url,'_blank');
       
    };

    const handleAccordion = ()=> {
        setExpanded(!expandAccordion);
        setSampleExpanded(false);
    };

    const handleSampleAccordion = ()=> {
        setSampleExpanded(!expandSampleAccordion);
        setExpanded(false);
    };

    const routeChange = (name)=>{
        console.log('ROUTE CHANGE');
        history.push(name);
    };

    const handleDateClose = ()=> {
        setSubjectInfoOpen(false);
    }
    const handleSocketMessage = async (message)=>{
        const asmtId = message?.id;
        const status = message?.status;
        const percCompleted = message?.percentageCompleted;

        console.log('Message:', message);
        // update assessment variable
        let found = assessmentsRef.current.find(item => item._id==asmtId);
        if(found){
            found["percentageCompleted"] = percCompleted;
            found["status"] =  status;
            if (status == 'complete'){
                await updateAssessments();
                // the current asmt will have all the data with the metrics now
                found = assessmentsRef.current.find(item=>item._id == asmtId);
                found["status"] = 'complete';
            }
            let localAsmts = [];
            for(let i=0; i<assessmentsRef.current.length;i++){
                localAsmts.push(assessmentsRef.current[i]);
            }
            _setAssessments(localAsmts);
            // without copy to this new variable, localAsmts, any React component referring to assessments or
            // assessmentsRef.current will not re-render
            console.log('Modified Asmts:', localAsmts);
        }
        else {
            // refresh asmt list
            if(userRef.current?.firebaseUser){
                updateAssessments();
            }
        }
 
    };

    const updateAssessments = async ()=> {
        const asmts = await getAllAssesments(userRef.current?.firebaseUser);
        console.log('Refreshed Asmts:', asmts);
        assessmentsRef.current = asmts;
        _setAssessments(asmts);
    };

    const newAsmtDialogComplete = ()=> {
        setAsmtDialogOpen(false);
        // get asmts for this user from the API
        updateAssessments();
        // perhaps show the user if the asmts are being processed?
        setExpanded(true);
    }


    const handleAssessmentsFilter = async (asmtProps) => {
        console.log('Asmt Filter Props:', asmtProps);
        var startDate = asmtProps.startDate;
        var endDate = asmtProps.endDate;
        var includeAllDates = asmtProps.includeAllDates?.disableDates;
        var parentEmail = asmtProps.parentEmail;
     

        const filterArray = [];



        // dates are in number format, convert to date object
        if(startDate){
            startDate = new Date(startDate);
        }
        console.log('Start Date:', startDate);
        if(endDate){
            endDate = new Date(endDate);
        }
        console.log('End Date:', endDate);
    
        if (parentEmail != '') {
          console.log('Parent Email:', parentEmail);
        }

        console.log('Include All Dates?:', includeAllDates);

        if (parentEmail != '' && parentEmail != null) {

          const subject = await getSubjectFromParentEmail(kiwiUser?.firebaseUser, parentEmail);
          if (subject?.length == 0) {
            console.log('No subject found for parent email:', parentEmail);
            return;
          }
          console.log('Subject with id:', subject[0]._id);
          filterArray.push( {subjectId: subject[0]._id});
        }   

        if (includeAllDates){
            // just add parent email in filter
        }
        else {
            // add start end dates
            filterArray.push( {date:{'$gt': startDate}});
            filterArray.push( {date:{'$lt': endDate}});
        }

        // get filtered asmts

        var fullFilter = {}
        console.log('filterArray:', filterArray);
        if (filterArray.length > 0) {
          fullFilter = {'$and':filterArray};
        }
        

        const asmts = await getFilteredAssesments(kiwiUser?.firebaseUser, parentEmail, JSON.stringify(fullFilter));
        console.log('Asmts:', asmts);
        assessmentsRef.current = asmts;
        _setAssessments(asmts);
    
    }

    {
        return (
            <div>
                <SocketIOContext.Provider value={{socket}}>
                <div className={classes.paper}>
                <Grid container 
                    style={{'min-width':'345px'}}
                    spacing={4}
                >
                    

                <Grid item xs={12}>
                    <Typography
                        display="inline"
                        ml={2}
                        color="warning"
                        variant="body2"
                    >
                    </Typography>
                    <Card mb={6}>
                    <CardContent>

                  
                    {!isAuthenticated && 
                        <Typography 
                        display='inline'
                        ml={2}
                        color="secondary"
                        variant='h5'>
                            Welcome to Cribsy. 
                            {!isAuthenticated &&
                            <div>
                            <Button color="primary"
                                
                                onClick={()=>{routeChange(AppRoutes.SIGN_IN)}}
                                >
                                Sign in
                            </Button>
                                <Typography display='inline'>
                                to proceed.
                                </Typography>
                            </div> 
                            }
                        </Typography>
                    }
                    <Grid container xs>
                        <Typography
                            display="inline"
                            ml={2}
                            color="secondary"
                            variant="h6"
                        >
                            {t('chatbottitle', {ns:"dashboard"})}!
                        </Typography>
                    </Grid>
                    <Grid container xs>
                        <Typography
                            display="inline"
                            ml={2}
                            variant="body2"
                        >
                            {t('chatbotpara',{ns:'dashboard'})}.
                            {/* Today is {new Date(currentDate).toDateString()} */}
                        </Typography>
                    </Grid>
                    </CardContent>
                    </Card>
                </Grid>


                
                <Grid item xs={12}>
                    <Typography
                        display="inline"
                        ml={2}
                        color="warning"
                        variant="body2"
                    >
                    </Typography>
                    
                    <Card mb={6}>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                        {t('intro',{ns:'dashboard'})}
                        </Typography>
                        <Typography variant="body2" gutterBottom my={2}>
                        {t('intropara',{ns:'dashboard'})}
                        </Typography>
                        <Button variant="outlined" color="primary" onClick={()=>{showTutorialVideo();}}>
                            {t('howtobutton',{ns:'dashboard'})}
                        </Button>
                    </CardContent>
                    </Card>
                </Grid>
                    

                    <Grid item xs={12} sm={6} style={{display: 'flex'}}>
                        {/* <Typography
                            display="inline"
                            ml={2}
                            color="warning"
                            variant="body2"
                        >
                        </Typography> */}
                        <Actions backgroundImage="/images/rsz_baby1.jpeg"
                            authenticated={isAuthenticated}
                            title={dobCardTitle} description={dobCardDescription}
                            buttonText={dobButtonText} buttonCallback={dobButtonCallback}/>
                    </Grid>


                    <Grid item xs={12} sm={6} style={{display: 'flex'}}>
                        {/* <Typography
                            display="inline"
                            ml={2}
                            color="warning"
                            variant="body2"
                        >
                        </Typography> */}
                        <Actions backgroundImage="/images/rsz_3baby-in-a-basket.jpeg"
                            authenticated={isAuthenticated && uploadEnabled}
                            title={videoCardTitle} description={videoCardDescription}
                            buttonText={videoButtonText} buttonCallback={videoButtonCallback}/>
                    </Grid>
                    
                    <Grid item md={12} sm={12} xs={12} >
                        
                        <div>
                        { assessments &&                
                            <Accordion expanded={expandAccordion} >
                                <AccordionSummary
                                    aria-controls="panel1a-content"
                                    expandIcon={<ExpandMoreIcon color="primary"/>}
                                    IconButtonProps={{
                                        onClick:handleAccordion
                                    }}
                                    id="update-assessment-header"
                                >
                                    <Typography
                                        display="inline"
                                        ml={2}
                                        color="secondary"
                                        variant="h6"
                                    >
                                        {t('videos',{ns:'dashboard'})}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container>
                                    <Grid item md={12} sm={12} xs={12}>
                                    <AssessmentInfoTable
                                        allAssessments={assessmentsRef.current}
                                        handleFilter = {handleAssessmentsFilter}
                                        />
                                    </Grid>

                                    <Grid item md={12} sm={12} xs={12}>
                                        <Typography variant="caption">
                                            {t('videosmsg',{ns:'dashboard'})}
                                        </Typography>
                                    </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        }
                        </div>
                    </Grid>

                    <Grid item md={12} sm={12} xs={12} >
                        
                        <div>
                        { assessments &&                
                            <Accordion expanded={expandSampleAccordion}>
                                <AccordionSummary
                                    aria-controls="panel1a-content"
                                    expandIcon={<ExpandMoreIcon color="primary"/>}
                                    IconButtonProps={{
                                        onClick:handleSampleAccordion
                                    }}
                                    id="update-assessment-header"
                                >
                                    <Typography
                                        display="inline"
                                        ml={2}
                                        color="secondary"
                                        variant="h6"
                                    >
                                        {t('samplevids',{ns:'dashboard'})}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container>
                                    <Grid item md={12} sm={12} xs={12}>
                                    <AssessmentInfoTable
                                        allAssessments={sampleAssessments}
                                        />
                                    </Grid>

                                    <Grid item md={12} sm={12} xs={12}>
                                        <Typography variant="caption">
                                            {t('samplevidmsg',{ns:'dashboard'})}.
                                            
                                        </Typography>
                                    </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        }
                        </div>
                    </Grid>

                </Grid>
                </div>


                <SubjectInfo isOpen={subjectInfoOpen} handleClose={handleDateClose}/>
                <NewAssessmentDialog
                    asmtToEdit = {asmtToUpdate}
                    isOpen = {asmtDialogOpen}
                    parentId = {parentId}
                    dialogComplete = {newAsmtDialogComplete}
                    subject = {subject}
                />
                </SocketIOContext.Provider>
            </div>
          );
    }
}

export default Dashboard;