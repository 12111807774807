import * as Schema from './mongoSchemas';

export  async function ReadJsonFromUrl(url: string) {

    await fetch(url)
      .then(res => res.json())
      .then( (out) => {
        console.log('Json output:', out);
        return out;
      })
      .catch(err => {throw err;});
      
}

  

export enum SaveStatusTypes {
  INITIALIZE = 'INITIALIZE',
  SAVING = 'SAVING',
  DONE = 'DONE'
  // CANCELLING = 'CANCELLING'
}

export type SignInError = {
  code: string,
  message: string
}


export type FirebaseUserProps = {
  // From the authentication service (firebase)
  displayName: string;
  email: string;
  uid: string;
  photoURL: string;
  bearerToken: string;
  firebaseUserObject: any;
}

// For the assessment table
export type AssessmentListUIProps = {
  assessmentId:string,
  parent:string, // This is the parent email.
  parentId:string, // needed for updating
  date:Date, // date of video
  dob:Date, // dob of subjectm
  duedate: Date,
  notes: string,
  video:string,
  subject?: object
};


export type AssessmentFilterProps = {
  startDate: Date,
  endDate: Date,
  includeAllDates: Boolean,
  parentEmail: string
}

export interface AssessmentPayloadProps extends Schema.AssessmentSchemaProps {
  date:Date|null;
  notes:string;
  videosForUpload:any[];
  videosThumbnailsForUpload?:any[];
  subjectDob?:Date,
  subjectDueDate?: Date,
  parentEmail:string,
  assessmentId?: string;
  subjectId?: string;
  subject?: Schema.SubjectSchemaProps | undefined;
}


export type UserProps = {

  // firebaseUser will be undefined if the user is not
  // the one that is current logged in.
  firebaseUser?:FirebaseUserProps;
  // UI related property, can probably get factored out.
  isAuthenticated: boolean;
  phoneNumber:string;
  authenticationInProcess: boolean;
  // Properties that match database names
  // I would expect these to be replaced by EmmaUserSchemaProps
  // for consistancy
  userId?: string; // firebase id?
  createdAt?: Date; // hmm should this be undefined?
  firstname: string;
  lastname: string;
  authLevel: number;
  localDisplayName?: string;
  localEmail?:string;
  _id?: string,
  tos: boolean,
  subject?: Schema.SubjectSchemaProps
  
}

export type SubjectProps = {
  _id?: string;
  parentid?: string;
  dob?: Date;
  duedate?: Date;
  gestage: number; // gestational age in number of days
}


export interface AssessmentCreationFeedBackUIProps{
  bytesTransferred:number,
  totalSize:number,
  fileName: string,
  videoTotal:number
  currentVideoCount:number,
  genericMessage?:string
}


export function convertUserDataToUserPropType(rawUserData:any):Partial<UserProps>{
  if(!rawUserData) return {};
  // Minor house keeping, not sure why any more though
  let userData = rawUserData;
  // Really don't know why this is here or when this would even happen. dlp
  if(typeof rawUserData === 'string') {
    // userData =JSON.parse(rawUserData);
    userData = JSON.parse(rawUserData); 
  }
  // In case of empty data, don't panic, just return empty
  if(Object.keys(userData).length === 0) return {...emptyUserState};

  //Clear out the props (or possible props) from the db that don't have a direct match
  // Since the type props and properties returned from the db match
  // not much is needed. but some work does need to be done to get them to match
  const {additionalProp1, email, subject, studyid, phoneNumber, ...matchingData} = userData;
  // console.log('Arge', email, 'split', matchingData);
  const userProp:Partial<UserProps> = {...matchingData };
  userProp.phoneNumber = phoneNumber;
  if(email){
    userProp.localEmail = email;
  }
  
  return userProp;
}

 

export const emptyFirebaseUserState:FirebaseUserProps = {
  displayName:'',
  email:'',
  uid:'',
  photoURL:'',
  bearerToken:'',
  firebaseUserObject:null
};

export const emptyUserState:UserProps = {
  firebaseUser:emptyFirebaseUserState,
  firstname:'', 
  lastname:'', 
  userId:undefined,
  authLevel:-1, //-1== unregistered, 0== normal user, 10===admin
  phoneNumber:'',
  createdAt:undefined,
  isAuthenticated:false,
  authenticationInProcess:false,
  tos: false,
  _id: undefined
};

export function createFirebaseUser(firebaseUser:any, bearerToken=''):FirebaseUserProps{
  // TS cares, but it doesn't care THAT much about types
  // so ugly dlp, why are you doing this???
  const initialUser:FirebaseUserProps = {...emptyFirebaseUserState};
  initialUser.photoURL = firebaseUser.photoURL;
  initialUser.uid = firebaseUser.uid;
  initialUser.email = firebaseUser.email;
  initialUser.displayName = firebaseUser.displayName;
  initialUser.bearerToken = bearerToken;
  initialUser.firebaseUserObject = firebaseUser;
  // // There is also a refreshToken...
  return initialUser;
}



export type DeviceProps = {
  _id?: string;
  deviceId: string; // device name
  category: string;
  description: string;
  orgId: string;
  updatedAt: string;
  status: number; // 0 for off and 1 for on
  statusUpdatedAt: string;
  notes: string;

}
export const emptyDeviceState: DeviceProps = {

  _id: undefined,
  deviceId: '', // device name
  category: '',
  description: '',
  orgId: '',
  updatedAt: '',
  status: 0, // 0 for off and 1 for on
  statusUpdatedAt: '',
  notes: ''
};