import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    paper: {
        paddingBottom: `env(safe-area-inset-bottom)`,
    },
    flexbox: {

        paddingTop: `env(safe-area-inset-top)`,
        paddingBottom: `env(safe-area-inset-bottom)`,
        paddingLeft: `env(safe-area-inset-left)`,
        paddingRight: `env(safe-area-inset-right)`,
        display:"flex",
        flexDirection:"row",
        justifyContent:"center",
        alignItems:"stretch",
        alignContent:"center"
    }
}));