import {Card, CardContent, Typography, AppBar, Toolbar, Dialog, DialogTitle, DialogContent} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';

import { useTranslation} from 'react-i18next';
import useStyles from './styles';

export default function TOS(props) {

    const {isOpen, handleClose} = props;
    const classes = useStyles();
    const {t, i18n} = useTranslation(); 

    return (
    
    <Dialog
    open={isOpen}
    onClose={handleClose}
    fullScreen
    className={classes.paper}
    >
    <DialogContent>
    <AppBar 
    className={classes.appbar}>
    <Toolbar>
    <IconButton
        edge="start"
        color="inherit"
        onClick={handleClose}
        aria-label="close"
    >
        <CloseIcon />
    </IconButton>

    <Typography variant="h6" component="div">
        
    {t("titletext", {ns:"tos"})}
    </Typography>
    </Toolbar>
    </AppBar>

      <Card mb={6} >
        <CardContent>
          <Typography variant="h6" gutterBottom>
             {t("titletext", {ns:"tos"})} 
          </Typography>
          <Typography variant="body2" gutterBottom my={2}>
            
            {t("welcome1", {ns:"tos"})}
            {t("welcome2", {ns:"tos"})} 
            <br></br>
            
            <br></br>
  
            <ol>
              <li>
                {t("term1", {ns:"tos"})} 
              </li>
              <li>
                {t("term2", {ns:"tos"})}
              </li>
  
              <li> {t("term3", {ns:"tos"})}
              </li>
              <li> {t("term4", {ns:"tos"})}
              </li>
              <li>{t("term5", {ns:"tos"})}
             </li>
              <li> {t("term6", {ns:"tos"})} 
              </li>
              <li>
             {t("term7", {ns:"tos"})}
              </li>
            </ol>
  
            {t("thanks1", {ns:"tos"})}
            
          </Typography>
          <Typography color="secondary">
          {t("thanks2", {ns:"tos"})}
          </Typography>
  
        </CardContent>
      </Card>
      </DialogContent>
    </Dialog>
    );
  }