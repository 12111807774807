import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import {blueGrey} from '@mui/material/colors/';
import { firebaseAuth } from '../../../firebaseConfig';
import firebase from 'firebase/app';
import {RecaptchaVerifier, signInWithPhoneNumber, getAuth} from 'firebase/auth';
import {isMobile, isBrowser, isIOS, isAndroid} from 'react-device-detect';
import { cfaSignInPhone, cfaSignInPhoneOnCodeSent, cfaSignInPhoneOnCodeReceived } from 'capacitor-firebase-auth';
import InApp from 'detect-inapp';

import Loading from '../../dashboard/components/Loading';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Capacitor } from '@capacitor/core';
import Footer from '../../../components/Footer/Footer';
import { showAlert } from '../../../common/utilities';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Input,
  Grid,
  InputLabel,
  TextField,
  Paper,
  MenuItem,
  Typography,
  CardContent,
  Card,
  Snackbar, 
  Button
} from '@material-ui/core';


// styles
import useStyles from './styles';
// import classnames from 'classnames';

// logo
import google from '../../../images/google.svg';
import apple from '../../../images/apple.svg';
// context
import { useUserDispatch, loginUser, resetPassword, registerUser, externalUserVerification, externalUserVerificationApple } from '../../../context/UserContext';

import * as AppRoutes from '../../../common/routeNames';
import ResetPassword from '../resetpassword/ResetPassword';
import TOS
 from '../../documents/TOS';

 import { useTranslation } from 'react-i18next';
import { ElevatorSharp } from '@mui/icons-material';

function Login(props) {
  const classes = useStyles();
  const {t, i18n} = useTranslation();

  // global
  const userDispatch = useUserDispatch();

  // local
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  // const [activeTabId, setActiveTabId] = useState(0);
  // const [nameValue, setNameValue] = useState('');
  const [authMessage, setAuthMessage] = useState('');
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [loginValue, setLoginValue] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otpValue, setOTP] = useState('');
  const [otpMessage, setOtpMessage] = useState(t("enter_code", {ns:'login'}));
  const [passwordValue, setPasswordValue] = useState('');
  const [confirmPasswordValue, setConfirmPasswordValue] = useState('');
  const [expandForm, setExpandForm] = useState(false);
  const [phoneVerificationId, setPhoneVerificationId] = useState('');
  const [country, setCountry] = useState('USA');
  const [countryCode, setCountryCode] = useState('+1');
  const [isRegister, setRegister] = useState(false);

  const inapp = new InApp(navigator.userAgent || navigator.vendor || window.opera);
  const [tosOpen, setTOSOpen] = useState(false);
  const handleClose = ()=>{
    setTOSOpen(false);
  }
  useEffect(() =>{
    if(authMessage === ''){
      setIsSnackbarOpen(false);
    }else{
      setIsSnackbarOpen(true);
    }
  },[authMessage]);

  useEffect( ()=>{
    console.log('Login component');
    console.log('isAndroid:', isAndroid);
    console.log('isIOS:', isIOS);

    console.log('Inapp mobile:', inapp.isMobile);
    console.log('Inapp desktop:', inapp.isDesktop);
    console.log('inapp:', inapp.isInApp);
    console.log('inapp browser:', inapp.browser);

    console.log('Capacitor Plaform:', Capacitor.getPlatform());
    console.log('ISNATIVE:', Capacitor.isNativePlatform());

  },[]);

  // useEffect( ()=> {
  //   // destroy RecaptchaVerifier instance when the component unmounts
  //   let verifier = window.RecaptchaVerifier;
  //   return () => {
  //     verifier && verifier.clear()
  //   }
  // });
  
  const countries = [
    { 
      value:'+1',
      label:'USA'
    }
  ];

  const handleCountryChange = (e) => {
    console.log('Selected country:', e.target.value);
    let country = countries.find( (element)=>element.value==e.target.value);
    setCountry(country.label);
    console.log('Country:', country.label);
    console.log('Country code:', country.value);
    setCountryCode(country.value);
  };


  const closeSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      setIsSnackbarOpen(false);
      return;
    }
    // setIsSnackbarOpen(false);
  };
  
  const generateRecaptcha = () => {

    //firebase.auth().languageCode = i18n.language;
    //console.log('Setting auth language to:', i18n.language);
    window.RecaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
      'size': 'invisible',
      // 'expired-callback': () => {
      //   console.log('Recaptcha expired');
      // },
      'callback': (response) => {
        //recaptcha verified...sign in with phone number now
        console.log('Recaptcha verified...user can sign in now');
      }, firebaseAuth});

  };

  const requestOTP = (e) => {
    e.preventDefault();
    console.log('requesting OTP');
    // set the phoneNumber with country code
    const numberWithCountryCode = countryCode + phoneNumber;
    console.log('Phone number with country code:', numberWithCountryCode);
    if (phoneNumber.length !== 10){
      showAlert(t('alert',{ns:'auth'}), t('invalidphonenumber',{ns:'auth'}), t('ok',{ns:'auth'}) );
      //window.RecaptchaVerifier?.clear();
      return;
    }

    if (isMobile) {
      console.log('this is a mobile device');
      if (isIOS){
        console.log('It is a iOS device');
      }
      if (isAndroid){
        console.log('It is an Android device!');
      }
    }
    if (phoneNumber.length >= 10){
      console.log('Expanding OTP form');
      setExpandForm(true);
      if(!isIOS){//Not IOS
        generateRecaptcha();
        let verifier = window.RecaptchaVerifier;
        // verifier.render().then( (widgetId) => {
        //   console.log('Recaptcha widget id:', widgetId);
        // });
        console.log('Verifier:', verifier);
        console.log('Calling signInWithPhoneNumber:', numberWithCountryCode);
        firebase.auth().signInWithPhoneNumber(numberWithCountryCode, verifier)
          .then(confirmationResult => {
            console.log('Confirmation result:', confirmationResult);
            window.confirmationResult = confirmationResult;
          }).catch((error) => {
            console.log('verifier error:',error);
            window.RecaptchaVerifier?.clear();

          });
      }
      else{ //isIOS 
        console.log('Signing in phone:', numberWithCountryCode);
        cfaSignInPhone(numberWithCountryCode).subscribe(
          {
            error: (error) => {
              console.log('at error cfaSignInPhone!');
              console.log(error);
    
            },
            complete: () => {
              console.log('at complete cfaSignInPhone!');
            }
          }
        );

        cfaSignInPhoneOnCodeSent().subscribe(
          {
            next: (verificationId) => {
              console.log('at next cfaSignInPhoneOnCodeSent!');
              setPhoneVerificationId(verificationId);
              console.log('phone verification id:',verificationId);
            },
            error: (error) => {
              console.log('at error cfaSignInPhoneOnCodeSent!');
              console.log(error);

            },
            complete: () => {
              console.log('at complete cfaSignInPhoneOnCodeSent!');
            }
          }
        );

        cfaSignInPhoneOnCodeReceived().subscribe(
          {
            next: (verificationId, verificationCode) => {
              console.log('Ver id, code:', verificationId, verificationCode);
            },
            error: (error) => {
              console.log('Error at cfaSignInPhoneOnCodeReceived:', error);
            },
            complete: ()=> {
              console.log('at complete cfaSignInPhoneOnCodeReceived');
            }
          }
        );


      }
    }
    else{
      // phone number has to be 10 digits
      showAlert(t('alert',{ns:'auth'}), t('invalidphonenumber',{ns:'auth'}), t('ok',{ns:'auth'}) );
    }
  };


  const verifyOTP = (e) => {
    e.preventDefault();
    setOTP(e.target.value);
    let verificationCode = e.target.value;
    if (!isIOS){
      let localOTP = e.target.value;
      if (localOTP.length === 6){
        console.log('OTP:', localOTP);
        let confirmationResult = window.confirmationResult;
        console.log('ConfirmationResult:', confirmationResult);
        confirmationResult && confirmationResult.confirm(localOTP).then( (result) => {
          //user signed successfully
          const user = result.user;
          console.log('User signed in:', user);
        }).catch( (error) => {
          console.log('Unsuccessful login, ', error);
          showAlert(t('alert',{ns:'auth'}), t('invalidpasscode',{ns:'auth'}), t('ok',{ns:'auth'}) ); 
          //window.RecaptchaVerifier?.clear();
        });
      } else{
        //showAlert(t('alert',{ns:'auth'}), t('invalidpasscode',{ns:'auth'}), t('ok',{ns:'auth'}) ); 
      }
    }
    else{ //iOS 
      
      console.log('iOS verification code:',verificationCode);
      if (verificationCode != null && verificationCode.length == 6) {
        const credential = firebase.auth.PhoneAuthProvider.credential(phoneVerificationId, verificationCode);
        console.log('Credential:',credential);
        console.log('putting cred into signIn');
        setOtpMessage(t("login.trying"));
        firebase.auth().signInWithCredential(credential).then((auth) => {
          console.log('done with phone signin!');
          console.log(auth);
        }).catch((error) => {
          console.log('ERROR in SignInWithCredential');
          console.log(error);
          setOtpMessage(t("login.verify_code"));
        }).finally( ()=> {
          setOtpMessage(t("login.verify_code"));
        });
      }
      else{
        //showAlert(t('alert',{ns:'auth'}), t('invalidpasscode',{ns:'auth'}), t('ok',{ns:'auth'}) ); 
      }
    }
  };


  const showTutorialVideo = () => {
    const url = (i18n.language=='en' || i18n.language=='en-US') ? 'https://youtu.be/jKOm49wJ9Aw': 
        'https://youtu.be/81fX677PoHM' ;
    console.log('Opening tutorial video for language: ', i18n.language);
    window.open(url,'_blank');
   
};

const showWebsite = () => {
  const url = 'https://earlymarkers.com/cribsy';
  window.open(url,'_blank');
 
};


  return (
    
    <Paper className={classes.wrapper} >
      <Loading isLoading={isLoading}/>
      <Grid
        container
        direction={'column'}
        align="center"
        spacing={3}
      >

        <Grid
          item
          xs={12} sm={8} md={8} lg={8} xl={8}>

          <Paper
            background={blueGrey[100]}
            elevation={3}
            padding={4}
          >
            <Card raised>
              <CardContent>
                <Typography
                  align="center"
                  component="h1"
                  gutterBottom
                  variant="h3"
                  color='secondary'
                >
                  {t('greeting', {ns:'login'})}
                </Typography>
                <Box
                  align="center"
                >
                  { 
                  // (!isIOS && !isAndroid) &&
                  <Button
                    className={classes.googleButton}
                    disabled={
                      isLoading
                    }
                    onClick={()=>externalUserVerification(
                      setIsLoading, 
                      setError, 
                      setAuthMessage, i18n, t)}
                      
                  >
                    <img
                      alt="google"
                      className={classes.googleIcon}
                      src={google}
                    />
                    &nbsp;{t("usegoogle", {ns:'login'})}
                  </Button>
                  }
                </Box>  

                {<Box
                  align="center"
                >
                  { 
                  (isIOS || !isAndroid || isBrowser) &&
                  // Apple sign in for Browser and iOS, not Android
                  <Button
                    className={classes.googleButton}
                    disabled={
                      isLoading
                    }
                    onClick={()=>externalUserVerificationApple(
                      setIsLoading, 
                      setError, 
                      setAuthMessage, i18n, t)}
                      
                  >
                    <img
                      alt="google"
                      className={classes.googleIcon}
                      src={apple}
                    />
                    &nbsp;{t("useapple", {ns:'login'})}
                  </Button>
                  }
                </Box>  
                }
              </CardContent>
            </Card>
          </Paper>

          <Paper
            elevation={3}
            padding={4}
          >
            <Card raised>
              <CardContent>
                <Typography align="center" component="h2" variant="h6" color='secondary'>
                 {t("phone_signin", {ns:'login'})} 
                </Typography>
                <form onSubmit={requestOTP}>
                  <FormControl
                    fullWidth
                    margin="normal"
                    required
                  >
                    <div>
                      <Grid container 
                        alignItems='flex-end' spacing={0} justifyContent='center' >

                        <Grid item md={3} sm={3} xs={3}>
                          
                          <TextField
                            variant='standard'
                            select
                            label={t('country', {ns:'login'})}
                            value={countryCode}
                            onChange={handleCountryChange}
                          >
                            {countries.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item md={3} sm={9} xs={9}>
                          <Input
                            variant='standard'
                            autoComplete="phone"
                            autoFocus 
                            id="phone"
                            name="phone"
                            placeholder={t('phone_number', {ns:'login'})}
                            onChange={e => { 
                              setPhoneNumber(e.target.value);} }
                            value={phoneNumber}
                          />
                        </Grid>
                      </Grid>
                    </div>
                    
                  </FormControl>

                  <Button
                    color="primary"
                    fullWidth
                    id="otpButton"
                    mb={2}
                    variant="outlined"
                    onClick={requestOTP}
                  >
                    {t("request_code", {ns:'login'})}
                  </Button>
                  {expandForm && 
                  <FormControl fullWidth  margin="normal" required>
                    <InputLabel htmlFor="otp">{t("otp_code",{ns:'login'})}:</InputLabel>
                    <Input
                      autoComplete="otp"
                      autoFocus 
                      id="otp"
                      name="otp"
                      onChange={verifyOTP}
                      value={otpValue}
                    />
                    <Typography variant='body1'>{otpMessage}</Typography>
                  </FormControl>
                  }
                </form>
              </CardContent>
            </Card>
            <Card raised >
              <CardContent> 
                <Typography
                  align="center"
                  component="h2"
                  variant="h6"
                  color='secondary'
                >
                {t("alternate_signin", {ns:'login'})}
                </Typography>

              <Accordion>
                <AccordionSummary
                  aria-controls="panel1a-content"
                  expandIcon={<ExpandMoreIcon />}
                  id="update-assessment-header"
                >
                  <Typography>{t("use_email", {ns:'login'})}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                 
                  <Grid container  spacing={1}>
                    <form>
                      <FormControl
                        fullWidth
                        margin="normal"
                        required
                      >
                        <InputLabel htmlFor="email">{t("email_address", {ns:'login'})}</InputLabel>
                        <Input
                          autoComplete="email"
                          autoFocus 
                          id="email"
                          name="email"
                          onChange={e => setLoginValue(e.target.value)}
                          value={loginValue}
                        />
                      </FormControl>
                      <FormControl
                        fullWidth
                        margin="normal"
                        required
                      >
                        <InputLabel htmlFor="password">{t("password", {ns:'login'})}</InputLabel>
                        <Input
                          autoComplete="current-password"
                          id="password"
                          name="password"
                          onChange={e => setPasswordValue(e.target.value)}
                          type="password"
                          value={passwordValue}
                        />
                      </FormControl>
                      {isRegister && 
                      
                      <FormControl
                        fullWidth
                        margin="normal"
                        required
                      >
                        <InputLabel htmlFor="password">{t("confirm_password", {ns:'login'})}</InputLabel>
                        <Input
                          id="confirmpassword"
                          name="confirmpassword"
                          onChange={e => setConfirmPasswordValue(e.target.value)}
                          type="password"
                          value={confirmPasswordValue}
                        />
                      </FormControl>}
                      {/* <FormControlLabel
                        control={<Checkbox
                          color="primary"
                          value="remember"
                        />}
                        label="Remember me"
                      /> */}
                    </form>

                    <Grid item xs={5} sm={5}>
                        <Button
                          color="primary"
                          disabled={
                            loginValue.length === 0 || passwordValue.length === 0 || isLoading
                          }
                          fullWidth
                          mb={2}
                          onClick={()=>
                            {
                              !isRegister ? 
                              loginUser(userDispatch,loginValue,passwordValue,props.history,setIsLoading,setError,setAuthMessage, t):
                              registerUser(userDispatch,loginValue,passwordValue,confirmPasswordValue,
                                props.history,setIsLoading,setError,setAuthMessage, setRegister, t);
                            }
                          }
                          variant="outlined"
                        >
                          {isRegister ? t("signup", {ns:'login'}) : t("signin", {ns:'login'})}
                        </Button>
                      </Grid>
                      <Grid item xs={7} sm={7}>  
                        <Button
                          color="secondary"
                          disabled={
                            isLoading
                          }
                          onClick={ ()=> resetPassword(loginValue, setIsLoading, setAuthMessage, t)}
                          //component={Link}
                          //fullWidth
                          //to={AppRoutes.REST_PASSWORD}
                        >
                        {t("forgot_pass", {ns:'login'})}
                        </Button>
                      </Grid>

                      <Card>
                          <CardContent>
                            <Grid container>
                              <Grid item xs={8} sm={8}>
                                <Typography> {!isRegister ? 
                                  t("no_account", {ns:'login'}) :
                                  t("have_account", {ns:'login'})} 
                                  </Typography>
                              </Grid>
                              <Grid item xs={4} sm={4}>
                                <Button color="primary"
                                  onClick={() => setRegister(!isRegister)}>
                                  {!isRegister ? t("signup", {ns:'login'}) : t("signin", {ns:'login'})}
                                </Button>
                              </Grid>
                            </Grid>
                          </CardContent>
                      </Card>
                  </Grid>

                </AccordionDetails>
              </Accordion> 
              
              </CardContent>
            </Card>
          </Paper>
        </Grid> 


        <Grid
          item
          xs={12} sm={8} md={8} lg={8} xl={8}
        >

          <Paper
            elevation={3}
            padding={4}
          >
            <Card mb={6} raised>
              <CardContent>
                        <Card mb={6}>
                            <CardContent>
                                <Typography variant="h6" gutterBottom color="secondary">
                                {t("whatis", {ns:'login'})}
                                </Typography>
                                <Typography variant="body2" gutterBottom my={2}>
                                {t("uncover1",{ns:"login"})}
                                &nbsp;
                                {t("uncover2",{ns:"login"})}
                                &nbsp;
                                {t("uncover3",{ns:"login"})}

                              </Typography>
                                <Button variant="outlined" color="primary" onClick={()=>{showTutorialVideo();}}>
                                    {t("make_video",{ns:"login"})}
                                </Button>
                            </CardContent>
                        </Card>

                        <Card mb={6}>
                            <CardContent>
                                <Typography variant="h6" color="secondary">
                                {t("early_access", {ns:'login'})}
                                </Typography>
                                <Typography variant="body2" gutterBottom my={2}>
                                {t("early_access_details",{ns:"login"})}
                                </Typography>

                                <Button variant="outlined" color="primary" onClick={()=>{showWebsite();}}>
                                  {t("learn_more", {ns:"login"})}
                                </Button>
                            </CardContent>
                        </Card>

              </CardContent>
            </Card>
            
            <Card mb={6} raised>
              <CardContent>
                <Footer/>
              </CardContent>
            </Card>

          </Paper>
        </Grid>

      {/* {            
      <Grid container align="left">
        <Grid item xs={10} sm={10} alignItems="flex-start">
            <Typography color="secondary" onClick=
              {()=>{
              setTOSOpen(true);}}
            > 
            Terms of service 
            </Typography>
        </Grid>
      </Grid>
      } */}


      </Grid>
      
      

    
      <TOS
        isOpen={tosOpen}
        handleClose={handleClose}
      />

      <div id="recaptcha-container">

      </div> 
      <Snackbar
        autoHideDuration={3000}
        message={authMessage}
        onClose={closeSnackBar}
        open={isSnackbarOpen}
      />  
    </Paper>
  );
}

export default withRouter(Login);