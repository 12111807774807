import React,  { useEffect, useState }  from 'react';
import {
  // Grid,
  // Paper,
  Typography,
  Divider,
  // Button
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';


import {UserInfoDialog} from '../../dialogs/UserInfoDialog';
import {
  CurrentUserInfo,
  UserTable
} from './components';
import { 
  // emptyParentUIState,
  // UIPropsForNewParentDialog,
  UserProps,
  emptyUserState
} from '../../common/entityUtilities';

import {useUserState} from '../../context/UserContext';
import { UserInformationTable } from './components/UserInformationTable.JSX';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    flexDirection:'column',
    display: 'flex',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

export default function Administration(props:any):JSX.Element{
  const initialAuthFilter = props?.history?.location?.state?.initialAuthFilter;
  const classes = useStyles();
  const activeUser = useUserState();
  const [parentCreateDialogIsOpen, setParentCreateDialogIsOpen] = useState(false);
  // null is used to reset the ui, kind of hackish...
  const [userToUpdate, setUserToUpdate] = useState<UserProps|null>(emptyUserState);
  const [allOrgs, setAllOrgs] = useState<any[]>([]);


  useEffect( ()=>{
    const getOrgs = async () => {
        // get all the orgs
        console.log('Active user:', activeUser);
    };
    
    getOrgs();

    return () => {
        // this now gets called when the component unmounts
    };
  }, [activeUser]);

  const editOrCreateUser = (userInfo:UserProps|null) =>{
    //Order is important, first make visible then update user

    setParentCreateDialogIsOpen(true);
    console.log('The userinfo', userInfo);

    setUserToUpdate(userInfo);
  };

  const dialogComplete= () =>{
    // Reset the user to create/edit
    // doesn't seem to work if you reset in the dialog.
    setUserToUpdate(null);//reset the ui
    // setParentCreateDialogIsOpen(false);
    setParentCreateDialogIsOpen((prev)=> !prev);
  };

  // This should really be dealt with on the router level...
  if (activeUser.authLevel < 10){
    return (
      <Typography variant="h5">
        You don&apos;t have permission to view this page.
      </Typography>
    );
  }


  return (
    <div className={classes.root}>
      <Typography
        display="inline"
        gutterBottom
        variant="h3"
      >
            Admin Tasks
      </Typography>
      <Divider />
      <CurrentUserInfo
        editOrCreateUser = {editOrCreateUser}
      />
      {/* <UserTable
        editOrCreateUser = {editOrCreateUser}
      /> */}
      {/* <UserAndAssessmentTable/> */}
      <UserInformationTable
        editOrCreateUser = {editOrCreateUser}
        initialAuthLevelFilter = {initialAuthFilter}
      />
      <UserInfoDialog // there is another one in Assessment, what to do?
        dialogComplete = {dialogComplete}
        isOpen={parentCreateDialogIsOpen}
        userInformation={userToUpdate}
        allOrgs={allOrgs}
      />
    </div>
  );
};
