import { SubjectProps } from "./entityUtilities";

export interface CpapUserSchemaProps {
    userId?:string,//the mongoId
    email:string,
    uid?:string,//the firebase id, needed in order to create on mongo
    authLevel:number,
    firstname:string,
    lastname:string,
    created:Date,
    deviceIds?:string[],

  }
  export const emptyUserMongoSchema:CpapUserSchemaProps = {
    email:'',
    authLevel:	-1,
    firstname:	'',
    lastname:	'',
    created:new Date(),
    uid:'unauthenticatedId'
  };

  export const emptySubjectState: SubjectProps = {
    dob: new Date(),
    duedate: new Date(),
    gestage: 0,
    parentid: ''
  };

  export interface SubjectSchemaProps {
    _id: string;
    dob: Date;
    duedate: Date;
    gestage: number;
    parentid: string;
    assessments?: AssessmentSchemaProps[];
  };

  export interface AssessmentSchemaProps {
    _id?: string;
    date: Date|null;
    subjectId?: string;
    subject?: SubjectSchemaProps;
    video?:string;
    createdAt?:Date;
  };


// Copied from arlo-video-igestor ie used there too
export type VideoSchemaProps = {
  _id?:string, //not needed for new
  timestamp:Date,
  duration:number,
  hasBeenViewed:boolean,
  bucketName:string, // firebase bucket
  url:string, // accessable url for video
  thumbnailurl?:string, //accessable url for thumb
  videoStoragePath:string, //bucM8HA5WG-336-53075198ketPath, ownerId, deviceId, videoFilename
  //bucketPath, ownerId, deviceId, thumbnailFilename
  thumbnailStoragePath?:string, 
  jsonStoragePath?:string
}
