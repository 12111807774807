import React from "react";
import i18n from "i18next"; 
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";

import translations_en from './translations/en.json';
import translations_es from './translations/es.json';


const i18nInstance = i18n.createInstance();

i18nInstance
.use(I18nextBrowserLanguageDetector)
.init({
    // lng: 'en',
    fallbackLng: 'en',
    debug: false,
    resources: {
        en: translations_en,
        es: translations_es
    }
})


i18nInstance.on('initialized', function(options) {
    console.log('initialized i18next', options);
   
});

i18nInstance.on('languageChanged', function(lng) {
    console.log('[i18n] language changed to:', lng);
});

export default i18nInstance;