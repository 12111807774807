import {  Box,Button, Card, Dialog, DialogTitle, DialogContent, AppBar, Toolbar, 
    CarContent, Paper, Grid, CardContent, Typography } from "@material-ui/core";
import GaugeChart from 'react-gauge-chart';
import { useEffect, useState } from "react";

import { Spacing } from "@mui/system";

import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';
import HelpCenterTwoToneIcon from '@mui/icons-material/HelpCenterTwoTone';

import { useTranslation } from "react-i18next";
import useStyles from './styles';
const gridContainer = {
    display: "grid",
    gridTemplateColumns: "repeat(5, 1fr)"
  };
  
  /**
   * This approach works best if you have a variable number of columns at runtime.
   * Replace `gridContainer` with `gridContainer2` at line 27 to give it a try.
   */
  const gridContainer2 = {
    display: "grid",
    gridAutoColumns: "1fr",
    gridAutoRows: "1fr",
    gridAutoFlow: "column"
  };
  const gridContainerRow = {
    display: "grid",
    gridAutoColumns: "1fr",
    gridAutoRows: "1fr",
    gridAutoFlow: "row",
    p:"4"
  };
  const gridItem = {
    margin:'12px'
  };

  

export default function ReportMetricsGrid (props) {

    const {t, i18n} = useTranslation();
    const {isOpen, assessment, dialogComplete} = props;

    const [headTurn, setHeadTurn] = useState(0.5);
    const [videoQuality, setVideoQuality] = useState(0);
    const [upperBodySym, setUpperBodySym] = useState(0);
    const [upperBodySymPercent, setUpperBodySymPercent] = useState(0);
    const [lowerBodySym, setLowerBodySym] = useState(0);
    const [lowerBodySymPercent, setLowerBodySymPercent] = useState(0);
    const [motorRisk, setMotorRisk] = useState(0);

    const classes = useStyles();

    const cardStyle = {
        display:'block',
      };

    useEffect( ()=>{
        console.log('Current asmt metrics:', assessment?.notes);
        computeSingleHeadTurn();
        if (assessment){
            if (assessment?.notes?.quality_index){
                setVideoQuality( (assessment?.notes?.quality_index).toFixed(2));
            }
            if (assessment?.notes?.uppersym){
                setUpperBodySym( (assessment?.notes?.uppersym/100).toFixed(2));
                setUpperBodySymPercent( (assessment?.notes?.uppersym).toFixed(1));
            }
            if (assessment?.notes?.lowersym) {
                setLowerBodySym( (assessment?.notes?.lowersym/100).toFixed(2));
                setLowerBodySymPercent((assessment?.notes?.lowersym).toFixed(1));
            }
            if (assessment?.notes?.p){
                setMotorRisk( assessment?.notes?.p.toFixed(2));
            }

        }
    },[assessment]);

    const computeSingleHeadTurn = ()=>{
        if(!assessment?.notes) { return;}
        const left = assessment?.notes?.head_turn_left / 100;
        const right = assessment?.notes?.head_turn_right / 100;
        const center = assessment?.notes?.head_turn_center / 100;
        // start at 50%
        let turn = 0.5;
        turn = turn-left/2;
        console.log('Turn:', turn);
        turn = turn + right/2;
        console.log('Turn:', turn);
        console.log('[ReportGrid] Setting turn to:', turn);
        setHeadTurn(turn);

    };


    const showHeadTurnArticle = () => {
        const url = 'https://blog.earlymarkers.com/2023/05/head-turn-preference.html';
        window.open(url,'_blank');
       
    };

    const showSymmetryArticle = () => {
        const url = 'https://blog.earlymarkers.com/2023/05/symmetry-of-limb-poses-in-crib.html';
        window.open(url,'_blank');
       
    };

    const showMotorRiskArticle = () => {
        const url = "https://blog.earlymarkers.com/2023/05/neuromotor-risk-probability-from.html";
        window.open(url,'_blank');
    }

    return (
        
    <Dialog open={isOpen} fullScreen className={classes.paper}>
    
    
    <DialogContent>
    <AppBar className={classes.appbar}>
        <Toolbar>

            <IconButton
                edge="start"
                color="inherit"
                aria-label="close"
                onClick={dialogComplete}
                >
                <CloseIcon />
                </IconButton>
                <Typography variant="h6" component="div">
                {t('appbar', {ns:"report"})}
                </Typography>
        </Toolbar>
    </AppBar>
        <Card>
        <CardContent>
        <Typography variant="h6" component="div">
        {t('appbar', {ns:"report"})}
        </Typography>
        <Grid container direction="row" spacing={1} alignItems="stretch" >

        
        <Grid item xs={10} sm={10} md={10} lg={10} style={{display: 'flex'}}>

            {/* <Typography variant="h4">{t('title',{ns:'report'})}</Typography> */}
        </Grid>
        <Grid item xs={10} sm={10} md={10} lg={10} style={{display: 'flex'}}>
            <Typography color="secondary">
            {t('subtitle1',{ns:'report'})} <b>{t('subtitle2',{ns:'report'})}</b> {' '}
            {t('subtitle3',{ns:'report'})}.
            </Typography>
        </Grid>

        <Grid item xs={10} sm={4} md={4} lg={4} alignItems="stretch" style={{display: 'flex'}} >
            <Card sx={{ maxWidth: 345 }} style={{backgroundColor:'lightblue'}}>
                <CardContent>

                <GaugeChart id="chart1" animate={false}
                percent={videoQuality}
                nrOfLevels={25} textColor="#FFF" needleColor="#464A4F"
                colors={['#EA4228', '#F5CD19', '#5BE12C']}/>

                </CardContent>
            </Card>
            
        </Grid>
        <Grid item xs={10} sm={6} md={6} lg={6} style={{display: 'flex'}}>

            <Card sx={{ maxWidth: 345 }} style={cardStyle}>
                <CardContent>
                <Typography variant="h5">{t('videoq',{ns:'report'})} </Typography>
                <Typography>
                {t('videoqpara',{ns:'report'})}.
                </Typography>
                </CardContent>
            </Card>
        </Grid>



        
        <Grid item xs={10} sm={4} style={{display: 'flex'}} >
            <Card sx={{ maxWidth: 256 }} style={{backgroundColor:'lightblue'}}>
                <CardContent>

                <GaugeChart id="chart2" hideText animate={false}
                percent={headTurn}
                nrOfLevels={3} textColor='#5BE12C' needleColor="#464A4F"
                colors={['#F5CD19', '#5BE12C', '#F5CD19']}/>

                </CardContent>
            </Card>
        </Grid>

        <Grid item xs={10} sm={6} style={{display: 'flex'}} >
            <Card sx={{ maxWidth: 345 }} style={{cardStyle}}>
                <CardContent>
                <Typography variant="h5">{t('headturn',{ns:'report'})} </Typography>
                    <Typography>
                    {t('headturnpara',{ns:'report'})}.
                    <Button size="small" variant="outlined" color="secondary"
                onClick={showHeadTurnArticle}> {t('learnmore',{ns:'report'})}</Button>
                </Typography>

                </CardContent>
            </Card>
        
        </Grid>



        <Grid item xs={10} sm={4} style={{display: 'flex'}} >
            <Card sx={{ maxWidth: 345 }} style={{backgroundColor:'lightblue'}}>
                <CardContent>

                <GaugeChart id="chart3" percent={upperBodySym} hideText animate={false}
                nrOfLevels={3} textColor="#FFF" needleColor="#464A4F"
                colors={['#F5CD19', '#f5ee19', '#5BE12C']}/>

                </CardContent>
            </Card>
            </Grid>
            
        <Grid item xs={10} sm={6}  style={{display: 'flex'}} >
            <Card sx={{ maxWidth: 345 }} style={{cardStyle}}>
                <CardContent>

                <Typography variant="h5"> {t('uppersym',{ns:'report'})} </Typography>
                <Typography>
                {t('uppersympara1',{ns:'report'})}. 
                <Typography variant="body1" color="lightblue">    
                {t('uppersympara2',{ns:'report'})}: {upperBodySymPercent}%</Typography>
                <Button size="small" variant="outlined" color="secondary"
                    onClick={showSymmetryArticle}> {t('learnmore',{ns:'report'})}</Button>
                    </Typography>
                </CardContent>
            </Card>
        </Grid>



        <Grid item xs={10} sm={4}  style={{display: 'flex'}} >
            <Card sx={{ maxWidth: 345 }} style={{backgroundColor:'lightblue'}}>
                <CardContent>

                <GaugeChart id="chart4" percent={lowerBodySym} animate={false}
                hideText
                nrOfLevels={3} textColor="#FFFFFF" needleColor="#464A4F"
                colors={['#F5CD19', '#f5ee19', '#5BE12C']}/>

                </CardContent>
            </Card>
            </Grid>

        <Grid item xs={10} sm={6}  style={{display: 'flex'}} >
            <Card sx={{ maxWidth: 345 }} style={{cardStyle}}>
                <CardContent>

                <Typography variant="h5">{t('lowersym',{ns:'report'})}</Typography>
                <Typography>
                {t('lowersympara1',{ns:'report'})}.
                </Typography>
                <Typography variant="body1" color="lightblue">    
                {t('lowersympara2',{ns:'report'})}: {lowerBodySymPercent}%</Typography>
                </CardContent>
            </Card>
        </Grid>

        <Grid item xs={10} sm={4}  style={{display: 'flex'}} >

            <Card sx={{ maxWidth: 345 }} style={{backgroundColor:'lightblue' }}>
                <CardContent>

                <GaugeChart id="chart5" animate={false}
                percent={motorRisk}
                arcWidth={0.05} arcPadding={0.01}
                nrOfLevels={3} textColor="#FFF" needleColor="#464A4F"
                colors={['#F5CD19', '#f5ee19', '#5BE12C']}/>

                </CardContent>
            </Card>
            </Grid>
        <Grid item xs={10} sm={6} md={6} style={{display: 'flex'}} >
            <Card sx={{ maxWidth: 345 }} style={{cardStyle}}>
                <CardContent>

                <Typography variant="h5">{t('neuroindex',{ns:'report'})} </Typography>
                <Typography>
                {t('neuroindexpara1',{ns:'report'})}
                </Typography>
                <Typography variant="body1" color="lightblue">
                {t('neuroindexpara2',{ns:'report'})}
            
            <Button size="small" variant="outlined" color="secondary"
                onClick={showMotorRiskArticle}> {t('learnmore',{ns:'report'})}</Button> 
                </Typography>
                </CardContent>
            </Card>
        </Grid>
        </Grid>
        </CardContent>
        </Card>
    </DialogContent>
    
    </Dialog>
    
    )
    
}