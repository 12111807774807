/* eslint-disable react/no-multi-comp */
import React, {useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import {Redirect} from 'react-router-dom';
import {withRouter} from 'react-router-dom';

import * as RouteNames from '../../common/routeNames';
import { setUserInfo } from '../../common/apiSetUtilities';
import { showAlert, showPrompt } from '../../common/utilities';
import { Capacitor } from '@capacitor/core';
// import { NavLink as RouterNavLink } from 'react-router-dom';
import { checkIfStudyidValid, checkForStudyId } from '../../common/apiGetUtilities';
import {
  Avatar,
  Button,
  IconButton,
  Card as MuiCard,
  CardContent,
  Checkbox,
  Divider as MuiDivider,
  FormControlLabel,
  Grid,
  Snackbar,
  TextField,
  Typography
} from '@material-ui/core';
import { spacing } from '@material-ui/system';
import validator from 'validator';
import {isMobile, isBrowser, isIOS, isAndroid} from 'react-device-detect';
import { useUserState, useUserDispatch, UserActionTypes} from '../../context/UserContext';
import { SharedSnackbarConsumer } from '../../context/SnackbarContext';
import useStyles from './styles';
import { convertUserDataToUserPropType } from '../../common/entityUtilities';
import { checkIfEmailIsTaken } from '../../common/apiGetUtilities';
import TOS from '../documents/TOS';
import { sleep } from '../../common/utilities';
import { useTranslation} from 'react-i18next';
const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);

const CenteredContent = styled.div`
  text-align: center;
`;


function UserSettings(props){
  const classes = useStyles();
  // Global
  const userState = useUserState();
  const userDispatch = useUserDispatch();

  const {t, i18n} = useTranslation(); 
 
  // local
  const [tosChecked, setTosChecked] = useState(false);
  const [displayName, setDisplayName] = useState('');
  const [photoURL, setPhotoURL] = useState('');
  const [registeredDate, setRegisteredDate] = useState(userState.createdAt);
  const [email, setEmail] = useState(userState.email);
  const [uiemail, setUiEmail] = useState(userState.email);
  const [userEmail, setUserEmail] = useState(userState.email);
  const [studyIdAlertShown, setStudyIdAlertShown] = useState(false);
  const [studyid, setStudyid] = useState(t('notenrolled',{ns:'usersettings'}));
  const [isStudyIdValid, setStudyIdValid] = useState(false);
  const [studyidValidationMessage, setStudyidValidationMessage] = useState('Enter a valid study ID.');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [toDashboard, setToDashboard] = useState(false);

  const [firstName, setFirstName] = useState(userState.firstname);
  const [lastName, setLastName] = useState(userState.lastname);
  const [signature, setSignature] = useState(userState.signature);
  const [authProvider, setAuthProvider] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [emailValidationMessage, setEmailValidationMessage] = useState('');

  const [tosOpen, setTOSOpen] = useState(false);
  const handleClose = ()=>{
    setTOSOpen(false);
  }

  useEffect(() => {

    const setFunction = () => {

        // is it better to load data here then cache it for future usage?
        // instead of having all the info a larger entitiy.
        let localEmailCopy = userState.localEmail;

        //console.log('[UserSettings] The user state', JSON.stringify(userState));
        console.log('[UserSettings] userState email:', userState.email);
        const providerData = userState.firebaseUser.firebaseUserObject?.providerData[0];
        console.log('[UserSettings] Auth Provider ID:', providerData?.providerId);
        // can be 'password'
        // 'google.com'
        // or 'phone' 
        //
        setAuthProvider(providerData?.providerId);

        if (providerData?.providerId == 'phone'){
          setPhoneNumber(providerData?.phoneNumber); // this should be saved in the emmauser db object
          console.log('Provider says phone number is:', providerData?.phoneNumber);
        
        }

        setDisplayName(userState.localDisplayName);
        if (userState.firebaseUser.email !== null){
          console.log('Setting userEmail from firebaseUser email:', userState.firebaseUser.email);
          setEmail(userState.firebaseUser.email);
          setUserEmail(userState.firebaseUser.email);
          setUiEmail(userState.firebaseUser.email);
          localEmailCopy = userState.firebaseUser.email;
          setStudyIdValid(false);
        }
        else{
        console.log('Firebase user did not have email');
        console.log('user email:', userEmail);
        console.log('userState email:', userState.email);
        if (userEmail != null && userEmail != undefined && userEmail != '') {
            console.log('Setting userEmail to:',userEmail);
            setEmail(userEmail);
            //the state variable email is never used anywhere!
            
            setUserEmail(userEmail);
            setUiEmail(userEmail);
            localEmailCopy = userEmail;
            setIsEmailValid(true);
            setEmailValidationMessage('');
            setStudyIdValid(false);
          
        }
        else if (userState.localEmail != null && userState.localEmail != undefined && userState.localEmail != ''){
            console.log('Setting userEmail from userState localEmail:', userState.localEmail);
            setEmail(userState.localEmail);
            setUserEmail(userState.localEmail);
            setUiEmail(userState.localEmail);
            localEmailCopy = userState.localEmail;
            setIsEmailValid(true);
            setStudyIdValid(false);
            setEmailValidationMessage('');
        }
        }

        if (userState.tos){
          setTosChecked(true);
        }

        console.log('[UserSettings] userEmail:', userEmail);
        console.log('[UserSettings] localEmailCopy:', localEmailCopy);
        console.log('userState:', userState);
        if(userState.createdAt){
        // can you convert userState.created to a date string of format 'YYYY-MM-DD'?
          var date = new Date(userState.createdAt) ;
          // convert to a string of format 'YYYY-MM-DD' without the time
          const dateString = date.toLocaleDateString('en-EN', { year: 'numeric', month: '2-digit', day: '2-digit' });
          const spanishDateString = date.toLocaleDateString('es-ES', { year: 'numeric', month: '2-digit', day: '2-digit' });
          
          if (i18n.language == 'es' || i18n.language == 'es-ES'){
            console.log('Spanish date:', spanishDateString);
            setRegisteredDate(spanishDateString);
          } else if (i18n.language == 'en' || i18n.language == 'en-EN' || i18n.language == 'en-US'){
            console.log('English date:', dateString);
            setRegisteredDate(dateString);
          }
          
        }
        setPhotoURL(userState.firebaseUser.photoURL);

        if (isIOS || isAndroid) {

          // authProvider can be 'password'
          // 'google.com'
          // or 'phone' 
          // for 'google.com' and 'password', you already have a userEmail set
          // 'phone' pathway you need user to enter email
          console.log('showPrompt?', userEmail);
          console.log('authProvider:', providerData?.providerId);
          console.log('localEmailCopy:', localEmailCopy);
          if (providerData?.providerId == 'phone' && 
            (localEmailCopy == '' || localEmailCopy == undefined || localEmailCopy == null) 
            // && !Capacitor.isNativePlatform()
            // use Capacitor.isNativePlatform() to check if the platform is native/mobile
            ){
            showPrompt();
          }
        }
    };

    if (userState.firebaseUser) {
        setFunction();
    }
   
    


  }, []);



  useEffect ( ()=>{
    console.log('useEffect studyidvalid:', isStudyIdValid);
    console.log('isemailvalid:', isEmailValid);
    console.log('userState:', userState);
    if(isStudyIdValid == false 
      && Capacitor.isNativePlatform() // for now, only check on native platforms
      && (userState?.authLevel == null || userState?.authLevel <= 0) ) {
      alertUserAboutStudyId();
    }
  }, [isStudyIdValid, userEmail]);

  const alertUserAboutStudyId  = async () => {
    console.log('studyidValid?:', isStudyIdValid);
    console.log('userEmail:', userEmail);
    if (userEmail !== '' && userEmail !== undefined){
      console.log('Calling checkForstudyid');
      const value = await checkForStudyId(userState?.firebaseUser, userEmail);
       
      if(value !== ''){
        console.log('user had valid studyid');
        setStudyIdValid(true);
        setStudyid(value);
        setStudyidValidationMessage('');
      }
      else{
        setStudyid(t('notenrolled',{ns:'usersettings'}));
        console.log('alertshown?', studyIdAlertShown);
        if (!studyIdAlertShown){
          console.log('setting alertshown to true');
          setStudyIdAlertShown(true);
          const msg =  t('needstudyidmsg',{ns:'usersettings'});
          const returnValue = await showAlert(t('needstudyidtitle',{ns:'usersettings'}), t('needstudyidmsg',{ns:'usersettings'}),
          t('needstudyidok',{ns:'usersettings'}));
          //Toast.show( {text:msg});
        }
      }
      
    }

  };

  const showPrompt = async() => {
    console.log('Showing email dialog');

    // can be 'password'
    // 'google.com'
    // or 'phone' 
    // for 'google.com' and 'password', you already have a userEmail set
    // 'phone' pathway you need user to enter email
    console.log('AUTHPROVIDER:', authProvider);
    console.log('isIOS', isIOS);
    console.log('studyidValid? ', isStudyIdValid);
    console.log('userEmail:', userEmail);

    const value = await showAlert(t('needinfotitle',{ns:'usersettings'}) ,
      t('needinfomsg',{ns:'usersettings'}), t('needinfook',{ns:'usersettings'}));

  };

  const modifyUserSettings = () => {
    //Firebase user should always exist since that is our main log in.
    const firebaseUser = userState.firebaseUser;

    // Also note that an empty use is created if no matching user was found
    // in the mongodb. It is of type UserProps and is used by the user context

    // the call to register the user needs to be put elsewhere
    // but for now this will do since no other function calls the setuser
    const addingNewUserToDb = (userState.authLevel < 0); // -1 for unregisterd
    // Check to see if it is an unregistered user
    // then register or update accordingly.
    const mongoUserValuesToUpdate= {};//:Partial<EmmaUserSchemaProps> 
    if(addingNewUserToDb){
      mongoUserValuesToUpdate.authLevel = 0;
      mongoUserValuesToUpdate.created = new Date();//new Date().toISOString();
      mongoUserValuesToUpdate.uid = firebaseUser.uid;
      mongoUserValuesToUpdate.email = firebaseUser.email;
      mongoUserValuesToUpdate.phoneNumber = phoneNumber;
      console.log('firebaseuser email:', firebaseUser.email);
    }
    mongoUserValuesToUpdate.firstname = firstName;
    mongoUserValuesToUpdate.lastname = lastName;

    if (firebaseUser.email === null){
      let lowercaseEmail = String(userEmail).toLowerCase();
      mongoUserValuesToUpdate.email = lowercaseEmail;
      console.log('useremail to update with:', lowercaseEmail);
    }
    mongoUserValuesToUpdate.tos = true; // has accepted TOS

    console.log('uservalues to update for new user:', mongoUserValuesToUpdate);
    setUserInfo(firebaseUser, userState._id, mongoUserValuesToUpdate)
      .then(updatedUser=>{
        // The updated user will have the emmauserid/mongoid
        console.log('The updated cribsyuser:', updatedUser);
        if (updatedUser?.tos) {
          console.log('settoschecked to true');
          setTosChecked(true);
        }
        const fixedUser = convertUserDataToUserPropType(updatedUser);
        console.log('The user', fixedUser);
        const createdDateString = new Date(updatedUser.createdAt).toDateString();
        console.log('Created at date string:', createdDateString);
        setRegisteredDate(createdDateString);
        userDispatch({ type: UserActionTypes.UPDATE, ...fixedUser });
        console.log('redirect to dashboard');
        setToDashboard(true);
        props.history.push('/');
        sleep(1000);
      });
  };

  const buttonText = userState.authLevel < 0 ? t('registerbutton',{ns:'usersettings'}): t('updatebutton',{ns:'usersettings'});

  if (toDashboard) {
    console.log('Rerouting to dashboard page');
    return (<Redirect to={RouteNames.DASHBOARD}/>);
  } else {
  return (
    <React.Fragment>
      <div>
        <Typography
          display="inline"
          gutterBottom
          variant="h3"
        >
          {t('pagetitle',{ns:'usersettings'})}
        </Typography>
        <Divider my={2}/>
        
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
          >
                
            <Card mb={6}>
            <CardContent>
                <Grid
                container
                spacing={3}
                xs={12}
                sm={12}
                md={12}
                >

                <Grid item md={8} xs={12} sm={12}>

                  <Typography
                  gutterBottom
                  variant="h4"
                  >
                  {userState.authLevel < 0 &&
                      <Grid item md={12} xs={12} sm={12}>

                      <Typography gutterBottom variant='h6' color='secondary'> 
                      {t('intromsg',{ns:'usersettings'})}
                      </Typography>

                      </Grid>
                  }
                  </Typography>
                </Grid>
                <Grid
                    item
                    md={8}
                    xs={8}
                    sm={8}
                >
                  
                    <TextField
                    id="useremail"
                    label={t('emaillabel',{ns:'usersettings'})}
                    fullWidth
                    disabled={authProvider !== 'phone'}
                    m={2}
                    
                    onChange={(e) => {
                      //console.log('Value:', e.target.value);
                        if (validator.isEmail(e.target.value)){ //is the entered text in the right format for an email?

                        setEmailValidationMessage(t('emailok',{ns:'usersettings'}));
                        checkIfEmailIsTaken(userState?.firebaseUser, e.target.value).then(
                            //is there any other cribsy in the database with this email?
                            (status)=>{
                            console.log('checkifemailistaken returned:', status);
                            if (!status){
                                setIsEmailValid(true);
                                setUserEmail(e.target.value);
                            }
                            else{
                                setEmailValidationMessage(t('emailtaken',{ns:'usersettings'}));
                                setIsEmailValid(false);
                            }
                            }
                        )
                        }
                        else {
                          setEmailValidationMessage(t('entervalidemail',{ns:'usersettings'}));
                          setIsEmailValid(false);
                        }
                    }}
                    value={uiemail}
                    variant="outlined"
                    />
                </Grid>

                <Grid
                    item
                    sm={4}
                    md={4}
                    xs={4}
                    >
                    <Typography variant='body2' color='primary'>
                        {emailValidationMessage}
                    </Typography>
                </Grid>
          

                <Grid
                    item
                    md={8}
                    xs={8}
                    sm={8}
                >
                    <TextField
                    id="firstname"
                    label={t('firstnamelabel',{ns:'usersettings'})} 
                    m={2}
                    onChange={(e)=>setFirstName(e.target.value)}
                    value={firstName}
                    variant="outlined"
                    />
                </Grid>
                <Grid
                    item
                    md={8}
                    xs={8}
                    sm={8}
                >
                    <TextField
                    id="lastname"
                    label={t('lastnamelabel',{ns:'usersettings'})}
                    m={2}
                    onChange={(e)=>{
                        console.log('lastname:', e.target.value);
                        setLastName(e.target.value);}}
                    value={lastName}
                    variant="outlined"
                    />
                </Grid>
                <Grid
                    item
                    md={4}
                    xs={4} sm = {4}
                >
                    <CenteredContent>
                    {photoURL !== ''?
                        (
                        <Avatar
                            alt=""
                            src={photoURL}
                        />):
                        (
                        <Avatar
                            alt=""
                            src="/static/img/avatars/avatar-1.jpg"
                        />)}
                    </CenteredContent>
                </Grid>


                {userState.authLevel >=0 ?  (
                    <Grid
                    item
                    md={4}
                    xs={8}
                    sm={6}
                    >
                    <TextField
                        InputProps={{
                        readOnly: true
                        }}
                        id="registeredDate"
                        label={t('regdatelabel',{ns:'usersettings'})}
                        m={2}
                        value={registeredDate}
                        variant="outlined"
                    />
                    </Grid>): (<Grid item></Grid>)}
                </Grid>
                <Grid
                container
                spacing={2}
                xs={12}
                >


                {(userState.authLevel<0 ||  !userState?.tos) ?  
                  (<Grid container spacing={1}>
                     <Grid item md={12} xs={12} sm={12}>

                      <Typography gutterBottom variant='h6' color='secondary'> 
                      {t('toslabel',{ns:'usersettings'})}
                      </Typography>

                    </Grid>
                    <Grid item xs={8}>
                    <Button variant='outlined' color='primary' onClick={()=>{setTOSOpen(true)}}>{t('tosbutton',{ns:'usersettings'})}</Button>    
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel

                      label={t('toscheckbox',{ns:'usersettings'})}
                      control={
                        <Checkbox color='secondary' 
                        checked={tosChecked}
                        onChange={(event)=>{
                          setTosChecked(event?.target.checked);
                          console.log('tos status:', event.target.checked);
                        }}
                      />
                      }
                    />
                  </Grid>
                  </Grid>): (<Grid item>
                    
                  </Grid>)
                }
                <Grid item xs={8} alignItems="center">

                    <SharedSnackbarConsumer>
                    {({openSnackbar}) => (
                        <Button
                        color="primary"
                        disabled={!isEmailValid || !tosChecked }
                        // allow user to register EVEN if no valid studyid
                        onClick= {()=> {
                            openSnackbar(t('savingmsg',{ns:'usersettings'}));
                            modifyUserSettings();}}
                        variant="contained"
                        >
                        {buttonText}
                        </Button>

                    )
                    }
                    </SharedSnackbarConsumer>


                </Grid>

                {
                Capacitor.isNativePlatform()  &&
                // for now, only show studyid on native platforms
                <Grid
                  item
                  md={6}
                  xs={8}
                  sm={8}
                >
                  <TextField
                    InputProps={{
                    readOnly: true
                    }}
                    fullWidth
                    InputLabelProps={{ shrink: true }} 
                    id="studyid"
                    label={t('studyidlabel',{ns:'usersettings'})}
                    m={2}
                    value={studyid}
                  />
                </Grid>
                }


                </Grid>

            </CardContent>
            </Card>
                

          </Grid>
        </Grid>

        <TOS
          isOpen = {tosOpen}
          handleClose={handleClose}
        />
      </div>
    </React.Fragment>
  );
  }
}



export default withRouter(UserSettings);

