import React, {useEffect, useState} from 'react';
import firebase, {firebaseAuth} from '../firebaseConfig';
import * as AppRoutes from '../common/routeNames';
import {PromptResult} from '@capacitor/dialog';
import {Toast} from '@capacitor/toast';
import { showAlert, showDialog, showPrompt, showToast } from '../common/utilities';

import { deleteUserAccount, deleteAllData } from '../common/apiDelUtilities';

import { User, GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import {BrowserView, MobileView, isMobile, isBrowser, isIOS, isAndroid} from 'react-device-detect';
import {
  SignInWithApple,
  SignInWithAppleResponse,
  SignInWithAppleOptions,
} from '@capacitor-community/apple-sign-in';
import { sha256 } from 'js-sha256';


import {
  UserProps,
  emptyUserState,
  createFirebaseUser,
  convertUserDataToUserPropType
} from '../common/entityUtilities';

import { getUserDataWithEmail, getUserDataWithUid } from '../common/apiGetUtilities';
import { Capacitor } from '@capacitor/core';
import { useTranslation } from 'react-i18next';

type Props = {
    children:React.ReactNode;
  };
  
  export enum UserActionTypes {
    LOGIN_SUCCESS = 'LOGIN_SUCCESS',
    SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS',
    LOGIN_FAILURE = 'LOGIN_FAILURE',
    USER_FAILURE = 'USER_FAILURE',
    UPDATE = 'UPDATE',
    AUTH_INPROCESS = 'AUTH_INPROCESS'
  }
  
  interface UserActionProps extends Partial<UserProps>{
    type:UserActionTypes;
  }
  
  const tokenConst = 'id_token';
  const UserStateContext = React.createContext<UserProps>(null!);
  const UserDispatchContext = React.createContext<React.Dispatch<any>>(null!);


function userReducer(state:UserProps, action:Partial<UserActionProps>) {

  const {type, ...noType} = action;
  switch (action.type) {
    case UserActionTypes.LOGIN_SUCCESS:
      console.log('LOGIN_SUCCESS');
      return { ...noType, isAuthenticated: true, authenticationInProcess:false };
    case UserActionTypes.SIGN_OUT_SUCCESS:
      console.log('SIGNOUT_SUCCESS');
      return { ...noType, isAuthenticated: false , authenticationInProcess:false};
    case UserActionTypes.LOGIN_FAILURE:
      console.log('LOGIN_FAILURE');
      return { ...noType, isAuthenticated: false , authenticationInProcess:false};
    case UserActionTypes.USER_FAILURE:
      console.log('USER_FAILURE');
      return { ...noType, isAuthenticated: true , authenticationInProcess:false};
    case UserActionTypes.AUTH_INPROCESS:
      console.log('AUTH_INPROCESS');
      return { ...noType, isAuthenticated: false, authenticationInProcess: true};
    // Specific updates might be good for the future
    case UserActionTypes.UPDATE: {
      console.log(type,'AUTH UPDATE', noType);
      const changeSet:any = {...noType};
      const newType = {...state, ...changeSet};
      console.log('Merged user:', newType);
      return newType;
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

async function loginAuthenticatedUser(firebaseUser:any, bearerToken:string, dispatch:any){//}, history:any){
  let emailFromApi = '';
  if(!firebaseUser){
  // Not really sure what to do in this situation
    dispatch({ type: UserActionTypes.LOGIN_FAILURE });
    return;
  }

  // console.log('user uid:', firebaseUser.uid);
  // console.log('token:', bearerToken);

  const convertedFirebaseUser = createFirebaseUser(firebaseUser, bearerToken);
  // Bearer token should not be set here
  try{ 
    // Try to get the user data from the database

    let userDataFromApi = await getUserDataWithEmail(firebaseUser.email, bearerToken, firebaseUser.uid);
    // for phone-auth, firebaseUser will not have email
    // so, get userdata from firebaseUser uid

    //console.log('[loginAuthenticatedUser] userdatafromapi:', userDataFromApi);
    // No user could be found, create a blank webui user
    let userForWebPage = {...emptyUserState};

    if (userDataFromApi == undefined || userDataFromApi?.email == ''){
      // try it with uid
      console.log('Searching for user with uid:', firebaseUser.uid);
      userDataFromApi = await getUserDataWithUid(firebaseUser.uid, bearerToken);
    }

    if(userDataFromApi == undefined || userDataFromApi?.email==''){
      // A user that matched the email was not found. Which means this is a brand new user that
      // nobody has added to the user list and has not logged in before. So create a new entity
      // and fill it with userful info. This entity will get passed around in the webapp and 
      // will be used to fill forms and eventually used to store in the database for future logins.
      userForWebPage.firebaseUser = convertedFirebaseUser;
      console.log('user not found in database');
    }else{
      console.log('user found in cribsy database');
      emailFromApi = userDataFromApi.email;
      //User has been put in the database either by registering or be being created by an admin
      //console.log('userdatafromapi:',userDataFromApi);
      const convertedProps = convertUserDataToUserPropType(userDataFromApi);
      //console.log('converted props:', convertedProps);
      userForWebPage = {...emptyUserState, ...convertedProps};
      userForWebPage.firebaseUser = convertedFirebaseUser;
      userForWebPage.userId = userDataFromApi.uid;
    } 
  
    // Set the display name normally we want to use the display name from the authentication
    userForWebPage.localDisplayName = convertedFirebaseUser.displayName;
    // This displayname setting logic is pretty nasty...dlp
    if(!userForWebPage.localDisplayName || userForWebPage.localDisplayName === ''){
      if(userForWebPage.authLevel < 0){
        userForWebPage.localDisplayName  = convertedFirebaseUser.email;
      }else{
        const displayName = `${userForWebPage.firstname} ${userForWebPage.lastname}`;
        if(displayName.trim() === ''){
          userForWebPage.localDisplayName  = convertedFirebaseUser.email;
        }else {
          userForWebPage.localDisplayName = displayName;
        }
      }
    }
    if(!convertedFirebaseUser.email){
      userForWebPage.localEmail = emailFromApi;
    }
    else {
      userForWebPage.localEmail = convertedFirebaseUser.email;
    }
    // Legacy, but keep it around just for fun
    localStorage.setItem(tokenConst, convertedFirebaseUser.email);
    //console.log('Dispatching with data:', userForWebPage);
    dispatch({ type: UserActionTypes.LOGIN_SUCCESS, ...userForWebPage });
    // Routing is odd, the login page shows while firebase is checking
    // that is not desirable, fix in later versions.
  }catch(err: any){
    // How to deal with errors from 
    //https://www.intricatecloud.io/2020/03/how-to-handle-api-errors-in-your-web-app-using-axios/
    const {message} = err as Error;
    console.log('Axios error ', err);
    if (message) {
      // client received an error response (5xx, 4xx)
      dispatch({ type: UserActionTypes.LOGIN_FAILURE });
    } 
  }
}

function UserProvider({ children }:Props) {
  // const history = useHistory(); // this should provide history...maybe context is not set right?
  const {t, i18n} = useTranslation();
  const [state, dispatch] = React.useReducer(userReducer, 
    emptyUserState);//, initLoginReducer);// TODO bring this back? probably not firebase deals here
  const [firebaseUser, setFirebaseUser] = useState<any>(null);
  
  useEffect(() =>{
    // Have firebase take care of its own authenication and send back the result
    // when it is done
    firebaseAuth.onAuthStateChanged(setFirebaseUser);

    
    // GoogleAuth.initialize({
    //   //clientId: '379868326448-elff52u0f75t3nj8hb63kafl0sm9q0d4.apps.googleusercontent.com',
    //   clientId: '1085599945273-d417b0bp34oidrrgf3hr38vtv41l76pg.apps.googleusercontent.com',
    //   // cribsy-demo client ID for web application
    //   scopes: ['profile', 'email'],
    //   //grantOfflineAccess: true
    // });
    if (!Capacitor.isNativePlatform()){
      console.log('Initializing Capacitor GoogleAuth');
      console.log('Web platform');
      GoogleAuth.initialize();
    }


  },[]);

    
  useEffect(() =>{
    // Have firebase take care of its own authenication and send back the result
    // when it is done
    firebaseAuth.onIdTokenChanged(async function(user){
      console.log('FIREBASE TOKEN CHANGED for user:');
      if (user){
        console.log('Setting firebaseuser');
        setFirebaseUser(user);
      }
    });

  },[]);

  useEffect(() =>{
    async function getFirebaseUserToken() {
      console.log('[getFirebaseUserToken] for user');
      if(!firebaseUser) return;
      let token:any = undefined;
      console.log('LOGGING in authenticated firebaseuser');
      if (firebaseUser?.email !== null && !firebaseUser?.emailVerified){
        //the firebaseuser has an email field, but hasn't been verified yet
        console.log('firebase user email has not been verified yet');
        const message = t('sendemail1',{ns:'auth'}) + ' ' + firebaseUser?.email + 
        '. ' + t('sendemail2',{ns:'auth'}) ;
        const value = await showDialog(t('verifyemailtitle',{ns:'auth'}),message,t('send',{ns:'auth'}),
          t('cancel',{ns:'auth'}));
        if (value){
          console.log('User wants a confirmation email sent');
          firebaseUser.sendEmailVerification().then( (result:any)=> {
            //showToast(t('checkemail',{ns:'auth'}));
            showAlert(t('verifyemailtitle',{ns:'auth'}),t('checkemail',{ns:'auth'}),t('ok',{ns:'auth'}));
            console.log('Sent email verification link:', result);

          }).catch( (error:any)=> {
            showToast(t('emailerror1',{ns:'auth'}));
            console.log('Error sending email link:', error);
          });
        }
        else{
          console.log('User cancelled email verification sending');
        }

        firebaseAuth.signOut().then(()=>{console.log('SIGNED OUT USER');});
        return; //return without logging the unverified user in
      }

      showToast(t('loginwait',{ns:'auth'}));
      dispatch({type:UserActionTypes.AUTH_INPROCESS});
      token = await firebaseUser.getIdToken(/*force refresh*/ true);
      loginAuthenticatedUser(firebaseUser, token, dispatch);//, history);
    }
    getFirebaseUserToken();
  },[firebaseUser]);

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

async function refreshToken(user: any){
  console.log('REFRESHING TOKEN');
  console.log('Firebaseuser with uid:', user.uid);

  let token:any = undefined;
  if(!user) return;

  if (user.firebaseUserObject) {
    token = await user.firebaseUserObject.getIdToken(true);
  }
  else if (user.firebaseUser){
    token = await user.firebaseUser.firebaseUserObject.getIdToken(true);
    user.firebaseUser.bearerToken = token;
  }
  user.bearerToken = token;
  console.log('New refreshed token:'); //, token);
  
  const convertedFirebaseUser = createFirebaseUser(user, token);
  console.log('Finished REFRESHING user');
}

function useUserState() {
  const context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error('useUserState must be used within a UserProvider');
  }
  return context;
}

function useUserDispatch() {
  const context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error('useUserDispatch must be used within a UserProvider');
  }
  return context;
}

// ###########################################################
function externalUserVerification(setIsLoading:any, setError:any, setAuthMessage:any, i18n:any, t:any){

  const providers = {
    googleProvider: new firebase.auth.GoogleAuthProvider(),
    emailAuthProvider: new firebase.auth.EmailAuthProvider(),
    appleProvider: new firebase.auth.OAuthProvider('apple.com')
  };
  providers.googleProvider.setCustomParameters({
    prompt: "select_account",
    locale: i18n.language.toString()
  });
  // select_account forced the web app to show google account choices
  // after signing out of a google account, the webapp will show the google account choices

  
  setAuthMessage(t('googlemsg1',{ns:'login'}));
  console.log('Attempting Google authentication');
  setIsLoading(true);
  // The signInWithPopup triggers the firebaseAuth.onAuthStateChanged
  // which is listened toin the usercontext
  // signInWithRedirect is suggested for mobile devices
  // we are able to completely logout and signin (with google acct choices) on Android
  // but not on Safari (desktop or mobile versions)
  // so, trying signInWithRedirect instead of signInWithPopup

  console.log('isAndroid:', isAndroid);
  console.log('isIOS:', isIOS);

  const platform = Capacitor.getPlatform();
  console.log('Capacitor Plaform:', platform);
  console.log('ISNATIVE:', Capacitor.isNativePlatform());
  if(!Capacitor.isNativePlatform()){
    console.log('Browser login');
    firebaseAuth.signInWithPopup(providers.googleProvider)
      .then((result)=>{
        console.log('Browser login completed');
        console.log('Google Authentication: Completed with result:', result);
        setAuthMessage(t('googlemsg2',{ns:'login'}));
        setIsLoading(false);
      })
      .catch(error =>{
        setIsLoading(false);
        console.log('Login error', error);
        setAuthMessage(t('googlemsg3',{ns:'login'}));
      });
  } 
  else { // native platforms
    console.log('Native login');
    signInWithCapacitor(setIsLoading, setError, setAuthMessage, i18n, t);

  }
}

async function signInWithCapacitor(setIsLoading:any, setError:any, setAuthMessage:any, i18n:any, t:any){
  console.log('Capacitor google login');


  
  try{


    const result = await  GoogleAuth.signIn();

   //console.log('Signin Result:', result);
   const credential = firebase.auth.GoogleAuthProvider.credential(result.authentication.idToken);
   firebaseAuth.signInWithCredential(credential)
   .then( (res)=> {
    console.log('[signInWithCredential] Done');
    }).
    catch( err => {
      console.log('Error signing in with GoogleAuth Cap plugin');
    });

  } catch (error){
    console.log('Capacitor Error:', error);
    setIsLoading(false);
  }

}


function externalUserVerificationApple(setIsLoading:any, setError:any, setAuthMessage:any, i18n:any, t:any){

  if (!Capacitor.isNativePlatform()){
    console.log('Web platform');
    signInWithAppleWeb(setIsLoading, setError, setAuthMessage, i18n, t);
  }
  else{
    console.log('Native platform');
    signInWithApple(setIsLoading, setError, setAuthMessage,i18n, t);
  }

}


function signInWithAppleWeb(setIsLoading:any, setError:any, setAuthMessage:any, i18n:any, t:any){
  const providers = {
    googleProvider: new firebase.auth.GoogleAuthProvider(),
    emailAuthProvider: new firebase.auth.EmailAuthProvider(),
    appleProvider: new firebase.auth.OAuthProvider('apple.com')
  };
  providers.appleProvider.setCustomParameters({
    prompt: "select_account",
    locale: i18n.language.toString()
  });

  setAuthMessage(t('applemsg1',{ns:'login'}));
  setIsLoading(true);
  firebaseAuth.signInWithPopup(providers.appleProvider)
  .then((result)=>{
    console.log('Browser login completed');
    console.log('Apple Authentication: Completed with result:', result);
    setAuthMessage(t('applemsg2',{ns:'login'}));
    setIsLoading(false);
  })
  .catch(error =>{
    setIsLoading(false);
    console.log('Apple Login error', error);
    setAuthMessage(t('googlemsg3',{ns:'login'}));
  });

}

// Native platform
function signInWithApple(setIsLoading:any, setError:any, setAuthMessage:any, i18n:any, t:any){

  const providers = {
    appleProvider: new firebase.auth.OAuthProvider('apple.com')
  };
  providers.appleProvider.setCustomParameters({
    prompt: "select_account",
    locale: i18n.language.toString()
  });

  const rawNonce = 'cribsy'; // any string would do here
  const nonce = sha256(rawNonce);
  //sha256.update(rawNonce).digest();
  let options: SignInWithAppleOptions = {
    clientId: 'com.earlymarkers.cribsy',
    redirectURI: 'https://cribsy-c233c.firebaseapp.com/__/auth/handler',
    scopes: 'email name',
    state: '12345',
    nonce: nonce,
  };
  console.log('Signing in with APPLE');

  SignInWithApple.authorize(options)
    .then(async (result: SignInWithAppleResponse) => {
      // Handle user information
      // Validate token with server and create new session
      console.log('Apple response:', result);
      console.log('Nonce:', nonce.toString());
      const credential = providers.appleProvider.credential({
        idToken: result?.response?.identityToken,
        rawNonce: rawNonce,
      });

      const userSignin = await firebaseAuth.signInWithCredential(credential);
      console.log('Signed in user:', userSignin);
      setIsLoading(false);
    })
    .catch(error => {
      // Handle error
      console.log('Apple error:', error);
      setIsLoading(false);
    });
}
/**
 * This will login a user to firebase authentication source or add the user
 * if it does not exists. 
 * @param dispatch 
 * @param loginEmail 
 * @param password 
 * @param history 
 * @param setIsLoading 
 * @param setError 
 * @param setAuthMessage 
 */
async function loginUser(dispatch:any, loginEmail:any, password:any, 
  history:any, setIsLoading:any, setError:any, setAuthMessage:any, t:any) {
  
  setError(false);
  setIsLoading(true);
  var msg = t('googlemsg4',{ns:'login'})
  setAuthMessage(msg + ` ${loginEmail}`);
  let signedUser:any = null;

  if (!!loginEmail && !!password) {
    // The login details is handled by authstate changed, so need to call the dispatch
       
    firebaseAuth.signInWithEmailAndPassword(loginEmail, password).then( (firebaseUser:any)=>{

      signedUser = firebaseUser;
      setError(null);
      setIsLoading(false);

    }).catch( (signInError: any)=> {

      //This does catch an error, but the same error seems to get through else where in the code
      // ugh soooo ugly, i feel very dirty doing this
      console.log("SIGNIN ERROR:", signInError);
      if(signInError.code && signInError.code === 'auth/user-not-found'){
        setAuthMessage(t('emailmsg1',{ns:'login'}));
        console.log('User not found. Try SIGNING UP');
        setIsLoading(false);
       
       
      }else{

        setAuthMessage(t('internal_error',{ns:'auth'}));
        setError(true);
        setIsLoading(false);
      }
    }).finally ( ()=> {
      console.log('Logged in user:', signedUser?.user);
      signedUser = signedUser?.user;
      //
      // we can leave it here and not check for email verification
      // when firebaseauth authenticates user, our logic will respond
      // firebase's onidtokenchanged() and onauthstatechanged() events will trigger
      // and set the firebaseUser state variable
      // this should trigger the loginAuthenticatedUser function here
      // which check for email verification and sends link when needed


      // if (signedUser != null){
      //   try{
      //     if(!signedUser?.emailVerified){
      //       console.log('Send user email verification link');
      //       signedUser.sendEmailVerification();
      //       console.log('Email verification link sent');
      //       setAuthMessage('Check your email. Click on verification link.');
      //     }
      //   }
      //   catch(error:any) {
      //     console.log('Error:', error);
      //     console.log('Signing user out...');
      //     signOut(dispatch, history);
      //   }}

      });

    

    
   
  } else {
    dispatch({ type: UserActionTypes.LOGIN_FAILURE });
    setError(true);
    setIsLoading(false);
  }
}


async function registerUser(dispatch:any, loginEmail:any, password:any, confirmPassword:any,
  history:any, setIsLoading:any, setError:any, setAuthMessage:any, setRegister:any, t:any) {

    setError(false);
    setIsLoading(true);
    if (password !== confirmPassword) {
      setAuthMessage(t('registermsg1',{ns:'login'}));
      setIsLoading(false);
      setError(true);
      return;
    }
    var msg = t('registermsg2',{ns:'login'});
    setAuthMessage(msg + ` ${loginEmail}`);

    try{
      // This triggers a base firebaseapp subscription started on init of userPRovider
      await firebaseAuth.createUserWithEmailAndPassword(loginEmail, password);
      setError(null);
      setIsLoading(false);
      setRegister(false);
      setAuthMessage(t('registermsg3',{ns:'login'}));
    } catch (createError){
      console.log('Bigger error', createError);
      setError(true);
      setIsLoading(false);
      if((createError as firebase.auth.Error)?.code === 'auth/email-already-in-use'){
        setAuthMessage(t('emailinuse',{ns:'auth'}));
      }
      else if ((createError as firebase.auth.Error)?.code === 'auth/weak-password'){
        setAuthMessage(t('weakpassword',{ns:'auth'}));
      }
    }
}

function signOut(dispatch:any, history:any) {
  firebaseAuth.signOut().then(()=>{
    localStorage.removeItem(tokenConst);
    dispatch({ type: UserActionTypes.SIGN_OUT_SUCCESS, ...emptyUserState });
    history.push(`${AppRoutes.SIGN_IN}`);
  });
}

async function deleteAccount(dispatch: any, history: any, userState: any, t: any):Promise<boolean> {
  //warn the user

  let message:string = t('deletemsg',{ns:'utils'});
  const confirmResult = await showDialog(t('deletetitle',{ns:'utils'}), message, t('ok',{ns:'utils'}), 
    t('cancel',{ns:'utils'}));

  if (confirmResult) {
    //next stage
    message = t('deleteconfirm',{ns:'utils'});
    const result: PromptResult = await showPrompt(t('deletetitle',{ns:'utils'}), message);
    console.log('Result:', result);
    console.log('Userstate:', userState);
    if (!result.cancelled){
      if (result.value == userState?.localEmail){
        //email match...ask for one final confirmation
        const finalConfirmation = await showDialog(t('deletetitle',{ns:'utils'}), 
        t('finalcheck',{ns:'utils'}),t('proceed',{ns:'utils'}), t('cancel',{ns:'utils'}));
        if (finalConfirmation){
          Toast.show({text: t('deleting',{ns:'utils'})});
          console.log('Deleting user account');
          //UNCOMMENT NEXT LINE IF YOU REALLY WANT TO LOSE ALL THE DATA!
          await deleteAllData(userState?.firebaseUser, userState, dispatch);
          
          //after deleting all data, delete the firebase account
          Toast.show({text: t('deletingcreds',{ns:'utils'})});
          await deleteUserAccount(userState?.firebaseUser);
          await showAlert(t('goodbye',{ns:'utils'}), t('sorry',{ns:'utils'}),t('ok',{ns:'utils'}));
          return true;
        }
      }
      else {
        Toast.show({text: t('emailnomatch',{ns:'utils'})});
        console.log('Email did not match');
        return false;
      }
    }
    else {
      console.log('Account will not be deleted');
      Toast.show({text: t('nodelete',{ns:'utils'})});
      return false;
    }

  }
  return false;

}
async function resetPassword(email: any, setIsLoading: any, setAuthMessage:any, t:any){
  
  if (!email || email == ''){
    setAuthMessage(t('resetmsg1',{ns:'login'}));
    return;
  }
  setIsLoading(true);
  firebaseAuth.sendPasswordResetEmail(email).then((result)=> {
    console.log('reset status:', result);
    setAuthMessage(t('resetmsg2',{ns:'login'}));
    setIsLoading(false);
  }).catch( (err) => {
    console.log('reset password error:', err);
    var msg = t('resetmsg3',{ns:'login'});
    setAuthMessage(msg + ' ' + err);
    setIsLoading(false);
  }
  ).finally( setIsLoading(false));

}





export { 
  refreshToken,
  externalUserVerification,
  externalUserVerificationApple,
  UserProvider, 
  useUserState, 
  useUserDispatch, 
  loginUser, 
  resetPassword,
  registerUser,
  signOut,
  deleteAccount
};

