import React, {useState} from 'react';
import styled from 'styled-components';

import { 
  Button as MuiButton, 
  Menu, 
  MenuItem 
} from '@material-ui/core';

import {
  CardContent,
  Card,
  CardActionArea,
  CardMedia,
  CardActions,
  Typography,
  Box
} from '@material-ui/core';

import {
  Loop as LoopIcon,
} from '@material-ui/icons';

import { spacing } from '@material-ui/system';

const Button = styled(MuiButton)(spacing);

const SmallButton = styled(Button)`
  padding: 4px;
  min-width: 0;

  svg {
    width: 0.9em;
    height: 0.9em;
  }
`;

const cardStyle = {
  display:'block',
};


const Actions = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentDate] = useState(new Date());
  const {backgroundImage, title, description, buttonText, buttonCallback, authenticated} = props;

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
   
    <Card sx={{ maxWidth: 345 }} style={cardStyle}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="140"
          image={backgroundImage}
          alt="baby"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button 
          disabled={!authenticated} size="small" color="primary" variant="outlined" onClick={buttonCallback}>
          {buttonText}
        </Button>
      </CardActions>
    </Card>
  );
};

export default Actions;
