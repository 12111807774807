
import './App.css';
import React, {useEffect, useState} from 'react';
import { HashRouter, Switch, Route, Redirect, useHistory} from 'react-router-dom';
import * as AppRoutes from './common/routeNames';
import Dashboard from './pages/dashboard/Dashboard';
import { useUserState } from './context/UserContext';
import Layout from './components/Layout/Layout';
import Login from './pages/auth/login/Login';
import ResetPassword from './pages/auth/resetpassword/ResetPassword';
import Documentation from './pages/documents/Documents';
import DebugLayout from './components/Layout/DebugLayout';
import { i18n } from 'i18next';
import { useTranslation } from 'react-i18next';
import { PushNotificationSchema, PushNotifications, Token, ActionPerformed } from '@capacitor/push-notifications';
import { showToast } from './common/utilities';
import {Capacitor} from '@capacitor/core';
import { addDeviceToken } from './common/apiSetUtilities';
export function App() {

const {t}  = useTranslation();

const {isAuthenticated, firebaseUser, authLevel} = useUserState();
const userState = useUserState();
const [deviceToken, setDeviceToken] = useState('');
const [isPushNotificationsAvailable, setPushNotificationsAvailable] = useState(Capacitor.isPluginAvailable('PushNotifications'));
const [capacitorNotifications, setCapacitorNotifications] = useState([]);

useEffect( ()=> {
  // use hook after platform dom ready
  // GoogleAuth.initialize({
  //   clientId: '379868326448-elff52u0f75t3nj8hb63kafl0sm9q0d4.apps.googleusercontent.com',
  //   scopes: ['profile', 'email'],
  //   grantOfflineAccess: true,
  // });
}, []);

// if authenticated, register for PUSH NOTIFICATIONS

useEffect( ()=> {
  if (!isAuthenticated) { return;}
  if (deviceToken == '') {return;}
  console.log('addDeviceToken:', deviceToken);
  addDeviceTokenToCribsyUser(deviceToken);

}, [deviceToken, isAuthenticated,userState?.userId]);


const addDeviceTokenToCribsyUser = (token)=>{

  console.log('Trying to add devicetoken to cribsy user:', userState?.userId);
  if (deviceToken == '' || userState.userId == null) { return; }

  //console.log('deviceIds of current cribsy user:', userState.deviceIds);
  const mongoUserValuesToUpdate = {};
  mongoUserValuesToUpdate.deviceIds = [];
  for (const id of userState.deviceIds){
    mongoUserValuesToUpdate.deviceIds.push(id);
  }
  //console.log('DeviceIds of cribsyuser before update:',mongoUserValuesToUpdate.deviceIds);

  addDeviceToken(firebaseUser, userState?._id, mongoUserValuesToUpdate, token)
    .then(updatedUser=>{
      // The updated user will have the userid/mongoid
      //console.log('The updated user after addDeviceToken:', updatedUser);
      //console.log('updated user devices:', updatedUser?.deviceIds);
    });

};
useEffect( ()=> {

  //ask user for permission?

  if(!isAuthenticated) return;
  console.log('Checking push permissions');
  isPushNotificationsAvailable && PushNotifications.checkPermissions().then((res) => {
    if (res.receive !== 'granted') {
      PushNotifications.requestPermissions().then((res) => {
        if (res.receive === 'denied') {
          showToast(t('permission_denied',{ns:'messaging'}));
        }
        else {
          showToast(t('permission_granted',{ns:'messaging'}));
          register();
        }
      });
    }
    else {
      showToast(t('registering_notification',{ns:'messaging'}));
      register();
    }
  });

},[isAuthenticated]);

const register = async() => {
  console.log('Registering Notifications via Capacitor');
  
  // On success, we should be able to receive notifications
  PushNotifications.addListener('registration',
    (token) => {
      //showToast('Push registration success with token' );
      //console.log('Push token:', JSON.stringify(token));
      console.log('Push token Value:', token.value);
      if (token.value){
        setDeviceToken(token.value);
      }
    }
  );

  // Some issue with our setup and push will not work
  PushNotifications.addListener('registrationError',
    (error) => {
      alert('Error on registration: ' + JSON.stringify(error));
    }
  );

  // Show us the notification payload if the app is open on our device
  PushNotifications.addListener('pushNotificationReceived',
    (notification) => {
      setCapacitorNotifications(notifications => [...notifications, { id: notification.id, title: notification.data.title, body: notification.data.body, type: 'foreground' }]);
      let title = '';
      let body = '';
      if(!notification.data?.title){
        title = notification.title;
      }
      else {
        title = notification.data.title;
      }

      if(!notification.data.body){
        body = notification.body;
      }
      else {
        body = notification.data.body;
      }
      const message = title + ': ' + body;
      showToast(message);
      console.log('Push message received');
      console.log('NOTIFICATION:', JSON.stringify(notification));
      //use SCOREIT API to stash this notification
      //message sender (server) would already have added this notification
      //don't do it on the client end
      //addNotification(firebaseUser, userState.emmauserId, notification.title, notification.body);
    }
  );

  // Method called when tapping on a notification
  PushNotifications.addListener('pushNotificationActionPerformed',
    async(notification) => {
     
      console.log('Notification actionperformed:', JSON.stringify(notification));
      if (notification.notification.data.body != '' && notification.notification.data.body != undefined){
        //use CRIBSY API to stash this notification
        const message = notification.notification.data.title + ': ' + notification.notification.data.body;
      //message sender (server) would already have added this notification
      //don't do it on the client end
        //addNotification(firebaseUser, userState.emmauserId, notification.notification.data.title, notification.notification.data.body);
      }

      //const notificationList = await PushNotifications.getDeliveredNotifications();
      //console.log('Delivered Notifications:', JSON.stringify(notificationList.notifications));
    }
  );

  // Register with Apple / Google to receive push via APNS/FCM
  PushNotifications.register();

  //get existing notifications that were received while app was offline?

  //const notificationList = await PushNotifications.getDeliveredNotifications();
  //console.log('Delivered Notifications:', notificationList.notifications);
  
};

function UserRoute({ component, ...rest }) {
  return (
    <Route
      {...rest}
      // This will need to be stricter, ie check authlevel and
      // then give each page a required authlevel to view.
      render={props =>
        isAuthenticated ? (
          React.createElement(component, props)
        ) : (
          <Redirect
            to={{
              pathname: `${AppRoutes.SIGN_IN}`,
              state: {
                from: props.location,
              },
            }}
          />
        )
      }
    />
  );
}

const PublicRoute = ({ component, ...rest }) =>{
  const redirectPage = (authLevel && authLevel < 0) ?AppRoutes.USER_SETTINGS:AppRoutes.LANDING;
  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? (
          <Redirect
            to={{
              pathname: `${redirectPage}`,
            }}
          />
        ) : (
          React.createElement(component, props)
        )
      }
    />
  );
};


return (
    <HashRouter>
      <div style={{ margin: '0 1rem 0 1rem', paddingTop: 'env(safe-area-inset-top)' }}></div>

      <Switch>
        
      <Route exact 
          path={AppRoutes.LANDING}
          render={() => <Redirect to={AppRoutes.DASHBOARD}/>}
          //component={Layout}
      />
      <UserRoute
          component={Layout}
          path={AppRoutes.APP}
      />
      <Route exact
        path={AppRoutes.DOCUMENTATION}
        component={Documentation}
      />
      <PublicRoute
          component={Login}
          path={AppRoutes.SIGN_IN}
      />
      <PublicRoute
        component={ResetPassword}
        path={AppRoutes.RESET_PASSWORD}
      />
        <Route component={Error} />
      </Switch>
    </HashRouter>
  );

}

export default App;
