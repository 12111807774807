import React, {useEffect, useState} from 'react';

import classNames from 'classnames';
// styles
import useStyles from './styles';

import {
    AppBar, 
    Box,
    IconButton,
    Toolbar,
    Menu,
    MenuItem,
} from '@mui/material';

// context
import {
    useLayoutState,
    useLayoutDispatch,
    toggleSidebar,
  } from '../../context/LayoutContext';


  import {
    Menu as MenuIcon,
    Person as AccountIcon,
    ArrowBack as ArrowBackIcon,
    PersonOutlineOutlined as PersonIcon,
    DuoRounded,
    DuoOutlined,
    ExitToApp as LoginIcon,
    PowerSettingsNew as LogoutIcon,
    DeleteSweepOutlined as DeleteIcon,
    VideoCall
  } from '@material-ui/icons';
// components
import { 
    Typography, 
    Button 
  } from '../Wrappers/Wrappers';

import {User, LogOut} from 'react-feather';
import { useUserDispatch, useUserState, signOut, refreshToken , deleteAccount} from '../../context/UserContext';
import {useTranslation} from 'react-i18next';
import NotificationButton from './components/NotificationButton';

export default function Header(props) {

    const [profileMenu, setProfileMenu] = useState(null);
    const [userName, setUserName] = useState(null);
  // Global
  const layoutDispatch = useLayoutDispatch();
  const layoutState = useLayoutState();

  const userDispatch = useUserDispatch();
  const loggedInUser = useUserState();

  const classes = useStyles();
  const {t, i18n} = useTranslation();

  useEffect( ()=>{
    //console.log('[Header] user:', loggedInUser);
  },[loggedInUser]);

return (
    <AppBar 
        className={classes.appBar}
        position="fixed"
    >
        <Toolbar className={classes.toolbar}>
        
        <IconButton
          className={classNames(
            classes.headerMenuButton,
            classes.headerMenuButtonCollapse,
          )}
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
        >
          {layoutState.isSidebarOpened ? (
            <ArrowBackIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
            
          ) : (
            <MenuIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          )}
        </IconButton>     
        <div className={classes.grow} />     
        <img
          alt="Logo"
          align="center"
          className={classes.logotype}
          src="/images/logos/logo-earlymarkers-24.png"
        />
        <div className={classes.grow} />
        <NotificationButton/>


        <IconButton
          aria-controls="profile-menu"
          aria-haspopup="true"
          className={classes.headerMenuButton}
          color="inherit"
          onClick={e => setProfileMenu(e.currentTarget)}
        >
          <User classes={{ root: classes.headerIcon }} />
        </IconButton>

        {
        <Menu
          anchorEl={profileMenu}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
          id="profile-menu"
          onClose={() => setProfileMenu(null)}
          open={Boolean(profileMenu)}
        >
          <div className={classes.profileMenuUser}>
            { loggedInUser?.isAuthenticated &&
            <Typography
              align="center"
              variant="h5"
              weight="medium"
            >
              {loggedInUser?.localDisplayName}
            </Typography>
            }

            { !loggedInUser?.isAuthenticated &&
              <Typography
                align="center"
                variant="h5"
                weight="medium"
              >
                User Sign In
              </Typography>
              }
          </div>
          {!loggedInUser?.isAuthenticated &&
          
            <MenuItem>
              <IconButton
                  className={classes.profileMenuLink}
                  color="primary"
                  onClick={() => signOut(userDispatch, props.history)}
              >
                  <LoginIcon/>
                  <Typography variant='h6' weight="medium">
                  Sign In
                  </Typography>     
              </IconButton>
            </MenuItem>
          }
          {loggedInUser?.isAuthenticated &&
            <MenuItem>
              <IconButton
                  className={classes.profileMenuLink}
                  color="primary"
                  onClick={() => signOut(userDispatch, props.history)}
              >
                  <LogoutIcon/>
                  <Typography variant='h6' weight="medium">
                  {t('signout',{ns:'dashboard'})}
                  </Typography>     
              </IconButton>
            </MenuItem>
          }
          {
            loggedInUser?.isAuthenticated &&
            <MenuItem>
            <IconButton
                className={classes.profileMenuLink}
                color="primary"
                onClick={async () => {
                  const returnValue = await deleteAccount(userDispatch, props.history, loggedInUser, t);
                  if(returnValue){
                    console.log('Signing out the deleted account');
                    signOut(userDispatch, props.history);
                  }
                }}
            >
                <DeleteIcon/>
                <Typography variant='h6' weight="medium">
                {t('deleteacct',{ns:'dashboard'})}
                </Typography>     
            </IconButton>
            </MenuItem>
          }
        

        </Menu>
        }
        
        </Toolbar>
    </AppBar>
);
}