import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import {Button, Grid } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import { useUserListState } from '../../../context/UserListContext';
// import CustomToolbar from './AddParentToolbar';

// const useStyles = makeStyles((theme: Theme) =>
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }),
);

// const columns = ['Name', 'Email', 'AuthLevel', 'DeviceIds'];

// const data = [
//   ['Joe James', 'Test Corp', 'Yonkers', 'NY'],
//   ['John Walsh', 'Test Corp', 'Hartford', 'CT'],
//   ['Bob Herm', 'Test Corp', 'Tampa', 'FL'],
//   ['James Houston', 'Test Corp', 'Dallas', 'TX'],
// ];
export function UserTable(props) {
  const {editOrCreateUser} = props;

  const { allUsers } = useUserListState();
  const [tableData, setTableData] = useState([]);
  const classes = useStyles();
  // // This data source is incorrect, we use UserProps
  // const getSourceData=()=>{
  //   return [
  //     // {'email':'emmatest02@launchbottle.com'}
  //     {'email':'emmatest02@launchbottle.com','uid':'wjJcnY6ZYpgQd1nsQ45AtdsC9O63','authLevel':0,'firstname':'John','lastname':'Doe','created':'2020-03-25T03:23:37Z','deviceIds':[]},
  //     {'email':'emmatest01@launchbottle.com','uid':'mLW1cyie3SdsTVEOLxIV5kGHsKx2','authLevel':0,'firstname':'Jane','lastname':'Doe','created':'2020-03-25T03:23:37Z','deviceIds':['EM0001','EM0002'],'sharedEmails':'emmatest02@launchbottle.com'}
  //   ];
  // };

  useEffect(()=>{
    setTableData(allUsers);
  },[]);

  useEffect(() =>{
    console.log('set table with:', allUsers);
    setTableData(allUsers);
  }, [allUsers]);

  const rowClick = (rowData, rowMeta) => {
    console.log(rowData, 'Rwo clic', rowMeta);
    editOrCreateUser(allUsers[rowMeta.dataIndex]); //rowIndex is for visible index
  };

  const options = {
    filterType: 'dropdown',
    download:false,
    filter:true,
    print:false,
    search:false,
    selectableRows:'none',
    onRowClick:rowClick,
    // eslint-disable-next-line react/no-multi-comp
    customToolbar: () => {
      return (
        <Button 
          color="primary"
          onClick={()=> {editOrCreateUser(undefined);}}
          variant="outlined"
        >
          Add user
        </Button>
        // <CustomToolbar />
      );
    }
  };
  const columns = [
    {
      name:'firstname',
      label:'First Name',
      options: { }
    },
    {
      name:'lastname',
      label:'Last Name',
      options: { }
    },    
    {
      name:'localEmail',
      label:'Email',
      options:{}
    },
  ];
  return(
    <MUIDataTable
      columns={columns}
      data={tableData}
      options={options}
      title={'User List'}
    />
  );
}
UserTable.propTypes = {
  editOrCreateUser: PropTypes.func
};