import axios from 'axios';
import { getCpapApiEndpoint, setAxiosDefaultHeaders } from './apiBaseUtilities';

import { 
    UserProps, 
    FirebaseUserProps
  } from './entityUtilities';
import { AssessmentSchemaProps, CpapUserSchemaProps, emptyUserMongoSchema, SubjectSchemaProps, VideoSchemaProps } from './mongoSchemas';

const cpapApiEndpoint = getCpapApiEndpoint();

export async function saveSubjectInfo(currentFirebaseUser:FirebaseUserProps|undefined,
  propUpdates:Partial<SubjectSchemaProps>, subjectId: string|undefined):Promise<Partial<SubjectSchemaProps>>{
  if(!cpapApiEndpoint || !currentFirebaseUser) return {};
  let parentId = propUpdates.parentid;
  console.log('Saving subject with parentid', parentId);
  console.log('data?', propUpdates);


  let subjectUser:Partial<SubjectSchemaProps> = {...propUpdates};

  const {uid, bearerToken } = currentFirebaseUser;
  setAxiosDefaultHeaders(uid, bearerToken);
  try{

    let url = `${cpapApiEndpoint}subjects`;//userToUpdate.userId
    if (subjectId) {
      url = `${cpapApiEndpoint}subjects/${subjectId}`;
    }

    console.log('URL: ', url);
  
    if(!subjectId){
      console.log('POST to subjects');
      const result = await axios.post(url, subjectUser);
      if(result.data){
        return result.data;
      }   
    } else {
      console.log('PUT to subjects:', subjectUser);
      const result = await axios.put(url, subjectUser);
      console.log('Result from PUT:', result);
      if(result.data){
        return result.data;
      }   
    }
  

  } catch (Error){
    console.log("API Error: ", Error);
  }

  return subjectUser;
}


export async function addDeviceToken(currentFirebaseUser:FirebaseUserProps|undefined, 
  userIdToUpdate:string|undefined,
  propUpdates:Partial<CpapUserSchemaProps>,
  tokenValue: string):Promise<Partial<CpapUserSchemaProps>>{
  if(!cpapApiEndpoint || !currentFirebaseUser) return {};


  const mongoUser:Partial<CpapUserSchemaProps> = {...propUpdates};

  // check to see if tokenValue is present in the deviceIds array
  let devices = propUpdates.deviceIds;

  let devicePresent = false;
  if (!devices || devices.length == 0) {
    devicePresent = false;
  }
  else {
    devicePresent = (devices.indexOf(tokenValue) > -1);
  }

  if (devicePresent) {
    console.log('Device id already present');
    return mongoUser; // the device is already present. no need to add 
  }

  if (!devices){
    devices = [];
  }
  devices?.push(tokenValue);
  propUpdates.deviceIds = devices;
  //console.log('Propupdates before adding to API:', propUpdates);


  setUserInfo(currentFirebaseUser, userIdToUpdate, propUpdates)
    .then(updatedUser=>{
      // The updated user will have the emmauserid/mongoid
      console.log('The user after token update with devices:', updatedUser?.deviceIds?.length);
    });
  

  return mongoUser;

}

export async function setUserInfo(currentFirebaseUser:FirebaseUserProps|undefined, 
    userIdToUpdate:string|undefined,
    propUpdates:Partial<CpapUserSchemaProps>):Promise<Partial<CpapUserSchemaProps>>{
    if(!cpapApiEndpoint || !currentFirebaseUser) return {};
    console.log('Saving id', userIdToUpdate);
    console.log('data?', propUpdates);

  
    let mongoUser:Partial<CpapUserSchemaProps> = {...propUpdates};
    if(userIdToUpdate === undefined){
      mongoUser = {...emptyUserMongoSchema, ...propUpdates};
    }
  
    const {uid, bearerToken } = currentFirebaseUser;
    setAxiosDefaultHeaders(uid, bearerToken);
    // Doing a post of an entity with a mongodb id will do the update
    // if not a new one is created.
  
    try{
      if(userIdToUpdate === undefined){
        const url = `${cpapApiEndpoint}users`;
        // make sure the mongoUser has all the required properties or
        // the api will return a 422 error.
        console.log('POST to create new cribsy user:', mongoUser);
        const result = await axios.post(url, mongoUser);
        if(result.data){
          return result.data;
        }    
      }else{
        const url = `${cpapApiEndpoint}users/${userIdToUpdate}`;//userToUpdate.userId
        console.log('PUT to update cpapuser:',mongoUser);
        console.log('Device Ids:', mongoUser.deviceIds);
        const result = await axios.put(url, mongoUser);
        if(result.data){
          return result.data;
        }   
      }
  
      return mongoUser;
    }catch(error){
      console.log('API ERROR:',error);
    }
    return mongoUser;  
  }


export async function setAssessment(firebaseUser:FirebaseUserProps|undefined, 
  asmtPayload:AssessmentSchemaProps){
  if(!cpapApiEndpoint || !firebaseUser){
    return undefined;
  }
  const url = `${cpapApiEndpoint}assessments`;

  console.log('Saving asmt to API:', asmtPayload);
  const {uid, bearerToken } = firebaseUser;
  setAxiosDefaultHeaders(uid, bearerToken);
  //console.log('bearerToken:', bearerToken);
  try{
    const result = await axios.post(url, asmtPayload);
    return result.data;
  }catch(error){
    console.log('API ERROR:',error);
  }
  return undefined;
}



export async function initiateProcessing(firebaseUser: FirebaseUserProps|undefined, asmt:any) {

  if(!cpapApiEndpoint || !firebaseUser){
    return undefined;
  }
  const url = `${cpapApiEndpoint}assessments/process`;
  console.log('Initiating processing with asmt data:', asmt);

  
  const {uid, bearerToken } = firebaseUser;
  setAxiosDefaultHeaders(uid, bearerToken);
  try{
    const result = await axios.post(url, asmt);
    return result.data;
  }catch(error){
    console.log('API ERROR:',error);
  }
  return undefined;

}
export async function setVideo(firebaseUser:FirebaseUserProps|undefined, videoPayload:VideoSchemaProps){
  if(!cpapApiEndpoint || !firebaseUser){
    return {};
  }
  const {uid, bearerToken } = firebaseUser;
  setAxiosDefaultHeaders(uid, bearerToken);
  const url = `${cpapApiEndpoint}/videos`;
  try{

    const response = await axios.post(url,videoPayload);
  
    return response.data;
  } catch (err){
    console.log('Failed to create video instance');
    console.log(err);
  }
  return {};
  
}

export async function sendCloudMessageFromBackend(firebaseUser:FirebaseUserProps|undefined,  data: string, tokens:string[]): Promise<boolean>
{

  if(!cpapApiEndpoint || !firebaseUser){
    return false;
  }

  const {uid, bearerToken } = firebaseUser;
  console.log('Tokens passed:', tokens);
  const tokenString = {'tokens': tokens}
  const apiendpoint = `${cpapApiEndpoint}assessments/sendCloudMessage/`  + data + `/` + JSON.stringify(tokenString);

  console.log('Sending to api endpoint:', apiendpoint);
  setAxiosDefaultHeaders(uid, bearerToken);
  try{
    const responseData = await axios.get(apiendpoint);
    console.log('sendMessage response:', responseData);
    return true;
  } catch(error){
    console.log('send message API ERROR:',error);
    return false;
  }
  return false;

}

export async function addNotification(currentFirebaseUser: FirebaseUserProps|undefined,
  userIdToUpdate: string | undefined,
  title: string|undefined, text: string|undefined){


  if(!cpapApiEndpoint) return;
  if(!currentFirebaseUser) return;
  const {uid, bearerToken } = currentFirebaseUser;
  setAxiosDefaultHeaders(uid, bearerToken);

  const payload = { 'userId':userIdToUpdate, 'title': title, 'text': text, url:'/' };
  console.log('Add Notification with payload:', JSON.stringify(payload));
  const url = `${cpapApiEndpoint}notifications`;
  try{
    await axios.post(url, payload);
  }catch(error){
    console.log('Add Notification API ERROR:',error);
  }  

}
