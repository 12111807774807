import React from 'react';
import {
  CardMedia,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

import { withTranslation } from 'react-i18next';

class VideoDetailsTabPanel extends React.Component {
  state = {
    isPlaying: false,
    volume: 0.7
  };

  handlePlay = () => {
    this.setState({isPlaying: true});
  };
  
  handlePause = () => {
    this.setState({isPlaying: false});
  };
  
  handleVolume = value => {
    this.setState({volume: value});
  };      

  

  render() {
    const {t, i18n} = this.props; // i18n is not needed
    const { 
      // children, 
      value, index, videoData, timestamp, videoOverlayData,
      videoOverlayDataOP, videoOverlayDataActivity, motorRisk, markers } = this.props;
    const { isPlaying, volume } = this.state;
    console.log('Timestamp:', timestamp);
    console.log('Language:', i18n.language);
    var localizedTimestamp = new Date(timestamp);

    if (i18n.language == 'en' || i18n.language == 'en-US' || i18n.language == 'en-EN'){
      localizedTimestamp = new Date(timestamp).toLocaleDateString('en-EN', 
      { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' });
      console.log('Localized timestamp:', localizedTimestamp);
    }
    else if (i18n.language == 'es' || i18n.language == 'es-ES'){
      localizedTimestamp = new Date(timestamp).toLocaleDateString('es-ES', 
      { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' });
      
    }

    if (value && value === 3){
      return (
        <div hidden={value !== index}>

          <DialogTitle id="max-width-dialog-title">
           Pose (infant)
          </DialogTitle>
      
          <DialogContent>
            <DialogContentText>
        Pose results overlaid.
            </DialogContentText>
            <CardMedia 
              component="video"
              controls
              height="256"
              image={videoOverlayData}
              title="Pose (infant)"
            />
          </DialogContent>
        </div>
      );
    }
    else if (value && value === 1) {
      // open pose overlay
      if (value == index){
        console.log('Metrics data:', motorRisk);
        if (!motorRisk?.p){
          motorRisk.p = 0;
        }
        console.log('video:', videoOverlayDataOP);
      }
      return (
        <div hidden={value !== index}>
          <DialogTitle id="max-width-dialog-title">
          {t('metricstitle',{ns:'videodetailstab'})}
          </DialogTitle>     
          <DialogContent>
        <DialogContentText>
          {motorRisk &&
            <ul>
          <li><b>{t('videoquality',{ns:'videodetailstab'})}:</b> {parseFloat(100*motorRisk?.quality_index).toFixed(1)}</li>
          <li> <b>{t('headturntitle',{ns:'videodetailstab'})}{' '}</b> 
          {t('headturnleft',{ns:'videodetailstab'})}: {parseInt(motorRisk.head_turn_left)}% {' '}
          {t('headturnright',{ns:'videodetailstab'})}: {parseInt(motorRisk.head_turn_right)}% {' '}
          {t('headturncenter',{ns:'videodetailstab'})}:{parseInt(motorRisk.head_turn_center)}%</li>
          <li> <b> {t('symmetrytitle',{ns:'videodetailstab'})}{' '}</b> 
          {t('symmetrylower',{ns:'videodetailstab'})}:{parseInt(motorRisk.lowersym)}% {' '}
          {t('symmetryupper',{ns:'videodetailstab'})}: {parseInt(motorRisk.uppersym)}%</li>
            
            <li> <b>{t('neuroindex',{ns:'videodetailstab'})}:</b> {parseFloat(100*motorRisk.p).toFixed(2)}% </li>
            </ul>
          }
        </DialogContentText>
        <p> {t('androidtext',{ns:'videodetailstab'})}</p>
        <CardMedia 
          component="video"
          controls
          height="256"
          image={videoOverlayDataOP}
          title="Metrics"
        />
          </DialogContent>
        </div>
      );
    }
    else if (value && value === 2){
      
      return (
        <div hidden={value !== index}>
         
          <DialogTitle id="max-width-dialog-title">
              Activities
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
            Activities detected in the snippet.
            </DialogContentText>           
           
          </DialogContent>
        </div>
      );
    } else {
      if (value == index){
        console.log('Raw video:', videoData);
      }
      return (      
        
        <div hidden={value !== index}>      
          <DialogTitle id="max-width-dialog-title">
             {t('rawvideo',{ns:'videodetailstab'})}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
          {t('rawvideotime',{ns:'videodetailstab'})} {' '} <b>{localizedTimestamp}</b>.
            </DialogContentText>
            <CardMedia 
              component="video"
              controls
              height="256"
              image={videoData != null && videoData.video}
              title="Activities"
            />
          </DialogContent>
        </div>
      );
    }
  }
}

export default withTranslation('videodetailstab',{withRef:true})(VideoDetailsTabPanel);