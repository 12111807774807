import { create } from '@mui/material/styles/createTransitions';
import React, {useState, useContext, useEffect, createContext} from 'react';
import { getSubjectDetails } from '../common/apiGetUtilities';
import { SubjectProps } from '../common/entityUtilities';
import { emptySubjectState } from '../common/mongoSchemas';
import { useUserState } from './UserContext';
const SubjectContext = createContext<SubjectProps>(null!);
const SubjectUpdateContext = createContext<React.Dispatch<any>>(null!);

type Props = {
  children:React.ReactNode;
};

export enum SubjectActionTypes {
  UPDATE = 'UPDATE'
}

interface SubjectActionProps extends Partial<SubjectProps> {
  type: SubjectActionTypes
}

function useSubjectState() {
  const context = useContext(SubjectContext);
  if (context === undefined){
    throw new Error('subjectstate must be used with a SubjectProvider');
  }
  return context;
}

function useSubjectDispatch() {
  const context = useContext(SubjectUpdateContext);
  if (context === undefined){
    throw new Error('subjectdispatch must be used with a SubjectProvider');
  }
  return context;
}

function subjectReducer (state: SubjectProps, action: Partial<SubjectActionProps>): any{

    const {type, ...otherData} = action;
    //console.log('[subjectReducer] ', state);

    switch(action.type) {
      case SubjectActionTypes.UPDATE:
        console.log('[reducer] UPDATE action:', {...otherData});
        const changeSet:any = {...state, ...otherData};
        return changeSet;
    }
}
function SubjectProvider({ children }: any) {
  
    const [subject, dispatch] = React.useReducer(subjectReducer, emptySubjectState)

    const kiwiUser = useUserState();

    useEffect( ()=>{
      async function getSubjectFromApi() {
        if(!kiwiUser) return;
          const parentid: string|undefined = kiwiUser._id;
          console.log('getSubject for Parentid:', parentid);
          //console.log('Kiwi user:', kiwiUser);
          if (kiwiUser?.firebaseUser){
            const subjectDetails = await getSubjectDetails(kiwiUser.firebaseUser, parentid);
            console.log('[SubjectProvider]:', subjectDetails);
            if (!subjectDetails) return;
            if(subjectDetails?.parentid !== ''){
              console.log('Dispatching subject details:', subjectDetails);
              dispatch({type:SubjectActionTypes.UPDATE,...subjectDetails});
            }
          }
      };
      getSubjectFromApi();

    }, [kiwiUser]);

    return (
      <SubjectContext.Provider value={subject}>
        <SubjectUpdateContext.Provider value={dispatch}>
          {children}
        </SubjectUpdateContext.Provider>
      </SubjectContext.Provider>
    );
  }


export {
  SubjectProvider,
  useSubjectState,
  useSubjectDispatch
}