import React from 'react';
import {
  Route,
  Switch,
  withRouter,
  Redirect,
} from 'react-router-dom';

import classnames from 'classnames';
// styles
import useStyles from './styles';
// components
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
import Footer from '../Footer/Footer';
import Documents from '../../pages/documents/Documents';
import UserSettings from '../../pages/usersettings/UserSettings';
// context
import { useLayoutState } from '../../context/LayoutContext';

import * as AppRoutes from '../../common/routeNames';
import Dashboard from '../../pages/dashboard/Dashboard';
import Administration from '../../pages/administration/Administration';

import Login from '../../pages/auth/login/Login';
import ResetPassword from '../../pages/auth/resetpassword/ResetPassword';
import { grey } from '@material-ui/core/colors';
import { CloudMessaging } from '../../pages/administration/CloudMessaging';

// This layout should be dependent on the user that is logged in.
// it should also matchish the sidebar layout
function Layout(props) {
  const classes = useStyles();
  // global
  const layoutState = useLayoutState();
  return (
    <div 
      className={classes.root}
    >
      <>
        <Header history={props.history} />
        <Sidebar />
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
        >
          <div className={classes.fakeToolbar} />
          <Switch>
            <Route exact
              component={Dashboard}
              path={AppRoutes.LANDING}
            />

            <Route
              component={Dashboard}
              path={AppRoutes.DASHBOARD}
            />


            <Route exact
              component={Documents}
              path={AppRoutes.DOCUMENTATION}
            />

            <Route exact
              component={Administration}
              path={AppRoutes.ADMIN_TASKS}
            />

            <Route
                component={Login}
                path={AppRoutes.SIGN_IN}
            />
            <Route
              component={ResetPassword}
              path={AppRoutes.RESET_PASSWORD}
            />
            <Route
              component={CloudMessaging}
              path={AppRoutes.MESSAGING}
              />
            <Route
              exact
              path={AppRoutes.SETTINGS}
              render={() => <Redirect to={AppRoutes.USER_SETTINGS} />}
            />

            <Route
              component={UserSettings}
              path={AppRoutes.USER_SETTINGS}
            />

          </Switch>
        
        <div className={classes.footer}>
          <Footer/>
        </div>
        </div>
      </>
    </div>
  );
}
export default withRouter(Layout);