/* eslint-disable react/no-multi-comp */
import {useState, useEffect, React} from 'react';
import styled from 'styled-components';
import { NavLink as RouterNavLink } from 'react-router-dom';

import {
  // Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  CardMedia,
  Chip as MuiChip,
  Button,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography as MuiTypography
} from '@material-ui/core';

import { spacing } from '@material-ui/system';
import {isMobile, isBrowser, isIOS, isAndroid} from 'react-device-detect';
import {Capacitor} from '@capacitor/core';
import useStyles from './styles';
import { useTranslation} from 'react-i18next';
import { t } from 'i18next';

// const NavLink = React.forwardRef((props, ref) => (
//   <RouterNavLink innerRef={ref} {...props} />
// ));

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

// const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Typography = styled(MuiTypography)(spacing);

const Code = styled.pre`
  margin-bottom: 0;
`;

const Chip = styled(MuiChip)`
  height: 20px;
  margin-top: -3px;
  font-weight: bold;
  font-size: 75%;
`;


function Introduction() {

  const {t, i18n} = useTranslation(); 
  return (
    <Card mb={6}>
      <CardContent>
        <Typography color="secondary" variant="h6" gutterBottom>
          {t('title',{ns:'intro'}) /*'Introduction'*/}
        </Typography>
        <Typography variant="body1" gutterBottom my={2}>
          {t('intropara',{ns:'intro'})}
          <ul>
            <li>
            
            <Link href="https://blog.earlymarkers.com/2023/05/head-turn-preference.html" target="_blank">
             {' '} <b>{t('headturn',{ns:'intro'})}:</b>
            </Link>
            {' '} {t('headturnpara',{ns:'intro'})}.
            </li>

            <li>
            
            <Link href="https://blog.earlymarkers.com/2023/05/symmetry-of-limb-poses-in-crib.html" target="_blank">
             {' '} <b>{t('symmetry',{ns:'intro'})}:</b> 
            </Link>
            {' '} {t('symmetrypara',{ns:'intro'})}.
            </li>

            <li>
            
            <Link href="https://blog.earlymarkers.com/2023/05/neuromotor-risk-probability-from.html" target="_blank">
             {' '} <b>{t('neuroindex',{ns:'intro'})}:</b> 
            </Link>
            {' '} 
            {t('neuroindexpara',{ns:'intro'})}
           </li>

            <li>
            
            <Link href="https://blog.earlymarkers.com/2023/05/motor-development-as-risk-indicator.html" target="_blank">
             {' '} <b>{t('motordev',{ns:'intro'})}:</b> 
            </Link>
            {' '} {t('motordevpara',{ns:'intro'})}.
            </li>
          </ul>
          <br></br>
           {t('moredata',{ns:'intro'})}.
        </Typography>
      </CardContent>
    </Card>
  );
}

function Contents() {
  const {t, i18n} = useTranslation(); 
  const [image1Path, setImage1Path] = useState(t('image1path',{ns:'howto'}));

  return (
    <Card mb={6}>
      <CardContent>
        <Typography color="secondary" variant="h6" gutterBottom>
          {t('title',{ns:'howto'}) }
        </Typography>
        <Typography variant="body2" gutterBottom my={2}>
          <b> {t('subtitle',{ns:'howto'})} </b> <br></br><br></br>
          <b>{t('home',{ns:'howto'})} -- </b> {t('homepara',{ns:'howto'})}.
          <br></br>
          <b> {t('videos',{ns:'howto'})} -- </b> {t('videospara',{ns:'howto'})}.
          <ul>
            <li> <b><i>{t('video',{ns:'howto'})}:</i></b> {t('videopara',{ns:'howto'})}. </li>
            <li> <b><i>{t('report',{ns:'howto'})}:</i></b> {t('reportpara',{ns:'howto'})}.
            <b> {t('seebelow',{ns:'howto'})}.</b> </li>
          </ul>
       
          <Card>
            <CardMedia
            component="img"
            image={ i18n.language=='es' ? ('/images/tusvideos1.png'): ('/images/yourvideos1.png') }
            alt="ui"
          />
          </Card>
        <br></br>
          <b>{t('settings',{ns:'howto'})} -- </b> {t('settingspara',{ns:'howto'})}.<br></br> 
          <br></br>

          <b>{t('about',{ns:'howto'})} -- </b>{t('aboutpara',{ns:'howto'})}.<br></br>
          <br></br>
          <b> {t('chatbot',{ns:'howto'})} -- </b> 
          {t('chatbotpara',{ns:'howto'})}.
        </Typography>

      </CardContent>
    </Card>
  );
}



function QuickStart() {
  const {t, i18n} = useTranslation(); 
  const [tutorialPath, setTutorialPath] = useState('https://youtu.be/jKOm49wJ9Aw');

  useEffect( ()=> {
    console.log('Quickstart page, language:', i18n.language);
    if (i18n.language=='es'){
      setTutorialPath('https://youtu.be/81fX677PoHM');
    }},[i18n.language]);

  return (
    <Card mb={6}>
      <CardContent>
        <Typography color="secondary" variant="h6" gutterBottom>
          {t('quickstart',{ns:'howto'})}
        </Typography>
        

        <Typography variant="body2" gutterBottom my={4}>
          {t('quickstartinstruction',{ns:'howto'})} {' '}
          <Link href={tutorialPath} target="_blank">
          {/* https://youtu.be/81fX677PoHM for SPANISH */}
            {t('quickstartlink',{ns:'howto'})}.
          </Link>{' '}
        </Typography>

          

        <Typography variant="body2" gutterBottom my={4}>
          {t('feedback',{ns:'howto'})} {' '}
          <Link href="https://earlymarkers.com/landing-page-em#contact-em" target="_blank">
            
          {t('contact',{ns:'howto'})} {' '}
          </Link>{' '}
        </Typography>
      </CardContent>
    </Card>
  );
}


function Changelog() {

  const {t, i18n} = useTranslation(); 
  const [currentPackageDesc, setCurrentPackageDesc] = useState('');

  useEffect( ()=> {console.log('Documentation page')},[]);

  return (
    <Card mb={6}>
      <CardContent>
        <Typography color="secondary" variant="h6" gutterBottom>
          {t('changelog',{ns:'about'}) /*'Changelog'*/}
        </Typography>
        <Chip color="secondary" label="v1.0" /> – 
        {t('june23',{ns:'about'}) /*'Initial Release'*/}
        
        <ul>
          <li>{t('release',{ns:'about'})}</li>
        </ul>
        <Divider my={3}></Divider>
        <Typography variant='body1'>
          {currentPackageDesc}
        </Typography>
        {/* {(isIOS || isAndroid) &&
        <Button variant='outlined' color='primary' 
          disabled
          onClick={handleUpdateApp}>
          Update App
        </Button> } */}
      </CardContent>
    </Card>
  );
}

function Documentation() {

  const {t, i18n} = useTranslation(); 
  const classes = useStyles();
  return (
    <div>
      <Typography variant='h3' gutterBottom>
        {t('about',{ns:'about'}) /*'About'*/}
      </Typography>

      <Changelog />

      <Typography variant="h3" gutterBottom display="inline">
        {t('documentation',{ns:'about'}) /*'Documentation'*/}
      </Typography>

      {/* <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/">
          Dashboard
        </Link>
        <Typography>Documentation</Typography>
      </Breadcrumbs> */}

      <Divider my={3} />

      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Introduction />
          <QuickStart />
        </Grid>
        <Grid item xs={12} md={6}>
          <Contents />
        </Grid>
      </Grid>
    </div>
  );
}

export default Documentation;
