import axios from 'axios';
import https from 'https';
import { getCpapApiEndpoint, setAxiosDefaultHeaders } from './apiBaseUtilities';
import { CpapUserSchemaProps, emptyUserMongoSchema, emptySubjectState, AssessmentSchemaProps } from './mongoSchemas';

import {
    UserProps,
    FirebaseUserProps,
    SubjectProps
} from './entityUtilities';
import { useUserState } from '../context/UserContext';

const cpapApiEndpoint = getCpapApiEndpoint();


export async function getCpapUserWithFilter(firebaseUser:FirebaseUserProps|undefined, email:any)
:Promise<CpapUserSchemaProps|undefined>{
  if(!cpapApiEndpoint || !firebaseUser || email == undefined){
    return emptyUserMongoSchema;
  }

  const {uid, bearerToken } = firebaseUser;
  const apiendpoint = `${cpapApiEndpoint}users/email/${email}`;
  setAxiosDefaultHeaders(uid, bearerToken);
  try{
  console.log('api endpt: ', apiendpoint);
//   console.log('uid:', uid);
//   console.log('token:', bearerToken);
    const responseData = await axios.get(apiendpoint);
    //console.log('response:', responseData);
    if(!responseData.data) return undefined;
    if(responseData.data.length === 0) return undefined;
    return responseData.data[0];
  }catch(error){
    console.log('401 ERROR on /users');
    return undefined;
  }
}

export async function getCribsyUserByUid(firebaseUser:FirebaseUserProps|undefined, uid:string)
:Promise<CpapUserSchemaProps|undefined>{
  if(!cpapApiEndpoint || !firebaseUser){
    return emptyUserMongoSchema;
  }

  const {uid:userUid, bearerToken } = firebaseUser;
  const apiendpoint = `${cpapApiEndpoint}users/uid/${uid}`;
  setAxiosDefaultHeaders(userUid, bearerToken);
  try{
  console.log('api endpt: ', apiendpoint);
//   console.log('uid:', uid);
//   console.log('token:', bearerToken);
    const responseData = await axios.get(apiendpoint);
    //console.log('response:', responseData);
    if(!responseData.data) return undefined;
    if(responseData.data.length === 0) return undefined;
    return responseData.data[0];
  }catch(error){
    console.log('401 ERROR on /users');
    return undefined;
  }
}


export async function getCpapUserById(firebaseUser:FirebaseUserProps|undefined, id:string)
:Promise<CpapUserSchemaProps|undefined>{
  if(!cpapApiEndpoint || !firebaseUser){
    return emptyUserMongoSchema;
  }

  const {uid, bearerToken } = firebaseUser;
  const apiendpoint = `${cpapApiEndpoint}users/uid/${id}`;
  setAxiosDefaultHeaders(uid, bearerToken);
  try{
  console.log('api endpt: ', apiendpoint);
//   console.log('uid:', uid);
//   console.log('token:', bearerToken);
    const responseData = await axios.get(apiendpoint);
    //console.log('response:', responseData);
    if(!responseData.data) return undefined;
    if(responseData.data.length === 0) return undefined;
    return responseData.data[0];
  }catch(error){
    console.log('401 ERROR on /users/uid');
    return undefined;
  }
}


export async function getSubjectDetails(firebaseUser:FirebaseUserProps,parentid: string|undefined)
  :Promise<SubjectProps|undefined>{
  if (!parentid) return undefined;
  if (!cpapApiEndpoint || parentid == null || parentid == ''){
    return undefined;
  }

  console.log('Getting subject with parent:', parentid);
  const {uid, bearerToken } = firebaseUser;
  const apiendpoint = `${cpapApiEndpoint}subjects/parent/${parentid}`;
  setAxiosDefaultHeaders(uid, bearerToken);
  try{
//   console.log('uid:', uid);
//   console.log('token:', bearerToken);
    const responseData = await axios.get(apiendpoint);
    //console.log('subjects get api response:', responseData);
    if(!responseData.data) return undefined;
    if(responseData.data.length === 0) return undefined;
    return responseData.data[0];
  }catch(error){
    console.log('401 ERROR on /subjects/parent/:id');
    return undefined;
  }}

export async function getSubjectFromId(firebaseUser:FirebaseUserProps,id: string|undefined)
:Promise<SubjectProps|undefined>{
  if (!id) return undefined;
  if (!cpapApiEndpoint || id == null || id == ''){
    return undefined;
  }

  console.log('Getting subject with id:', id);
  const {uid, bearerToken } = firebaseUser;
  const apiendpoint = `${cpapApiEndpoint}subjects/${id}`;
  setAxiosDefaultHeaders(uid, bearerToken);
  try{
  //   console.log('uid:', uid);
  //   console.log('token:', bearerToken);
    const responseData = await axios.get(apiendpoint);
    //console.log('subjects get api response:', responseData.data);
    if(!responseData.data) return undefined;
    return responseData.data;
  }catch(error){
    console.log('401 ERROR on /subjects/:id');
    return undefined;
  }
}

export async function getLatestAssesment(firebaseUser:FirebaseUserProps, subjectId: string|undefined):
  Promise<AssessmentSchemaProps|undefined>{

    if(!cpapApiEndpoint){
      return undefined;
    }

    const {uid, bearerToken } = firebaseUser;
    const apiendpoint = `${cpapApiEndpoint}assessments/latest/${subjectId}`;
    setAxiosDefaultHeaders(uid, bearerToken);
    try{

      const responseData = await axios.get(apiendpoint);
      //console.log('Asmts get api response:', responseData);
      if(!responseData.data) return undefined;
      return responseData.data;

    } catch (error){
      console.log('ERROR on /assessments:', error);
    }

  }


  export async function getAllAssesments(firebaseUser:FirebaseUserProps):
  Promise<AssessmentSchemaProps|undefined>{

    if(!cpapApiEndpoint || !firebaseUser || firebaseUser?.uid == ''){
      return undefined;
    }

    //console.log('firebaseUser:', firebaseUser);
    const {uid, bearerToken } = firebaseUser;
    if(bearerToken){
      console.log('[getAllAssessments] valid token passed');
    }
    const apiendpoint = `${cpapApiEndpoint}assessments`;
    setAxiosDefaultHeaders(uid, bearerToken);
    try{

      console.log('Hitting endpoint:', apiendpoint);
      const responseData = await axios.get(apiendpoint);
      //console.log('Asmts get api response:', responseData);
      if(!responseData.data) return undefined;
      return responseData.data;

    } catch (error){
      console.log('ERROR on /assessments:', error);
    }

  }




  export async function getSubjectByEmail(firebaseUser:FirebaseUserProps, email:string):
  Promise<SubjectProps|undefined>{

    if(!cpapApiEndpoint || !firebaseUser || firebaseUser?.uid == ''){
      return undefined;
    }

    console.log('firebaseUser:', firebaseUser);
    const {uid, bearerToken } = firebaseUser;
    if(bearerToken){
      console.log('[getAllAssessments] valid token passed');
    }
    const apiendpoint = `${cpapApiEndpoint}users/subjectbyemail/${email}`;
    setAxiosDefaultHeaders(uid, bearerToken);
    try{

      console.log('Hitting endpoint:', apiendpoint);
      const responseData = await axios.get(apiendpoint);
      console.log('Subject get api response:', responseData);
      if(!responseData.data) return undefined;
      return responseData.data;

    } catch (error){
      console.log('ERROR on /users/subjectbyemail:', error);
    }

  }

  export async function getFilteredAssesments(firebaseUser:FirebaseUserProps, email:string, filter:any):
  Promise<AssessmentSchemaProps[]|undefined>{

    if(!cpapApiEndpoint || !firebaseUser || firebaseUser?.uid == ''){
      return undefined;
    }

    console.log('Filter:', filter);
    const {uid, bearerToken } = firebaseUser;
    if(bearerToken){
      console.log('[getFilteredAssessments] valid token passed');
    }

    const apiendpoint = `${cpapApiEndpoint}assessments/filter/${filter}`;
    setAxiosDefaultHeaders(uid, bearerToken);
    try{

      console.log('Hitting endpoint:', apiendpoint);
      const responseData = await axios.get(apiendpoint);
      //console.log('Asmts get api response:', responseData);
      if(!responseData.data) return undefined;
      return responseData.data;

    } catch (error){
      console.log('ERROR on /assessments:', error);
    }

  }

  export async function getSampleAssesments(firebaseUser:FirebaseUserProps):
  Promise<AssessmentSchemaProps|undefined>{

    if(!cpapApiEndpoint || !firebaseUser || firebaseUser?.uid == ''){
      return undefined;
    }

    //console.log('firebaseUser:', firebaseUser);
    const {uid, bearerToken } = firebaseUser;
    if(bearerToken){
      console.log('[getSampleAssessments] valid token passed');
    }
    const apiendpoint = `${cpapApiEndpoint}assessments/samples`;
    setAxiosDefaultHeaders(uid, bearerToken);
    try{

      console.log('Hitting endpoint:', apiendpoint);
      const responseData = await axios.get(apiendpoint);
      //console.log('Asmts get api response:', responseData);
      if(!responseData.data) return undefined;
      return responseData.data;

    } catch (error){
      console.log('ERROR on /sampleassessments:', error);
    }

  }



  export async function getNotifications(firebaseUser:FirebaseUserProps|undefined){
    let notifications:any[] = [];
    if(!cpapApiEndpoint){
      notifications = [];
    }else{
      if (!firebaseUser){ return;
      }
      const {uid, bearerToken } = firebaseUser;
      const url = `${cpapApiEndpoint}notifications`;
      setAxiosDefaultHeaders(uid, bearerToken);
      try{
        const responseData = await axios.get(url);
        console.log('Notifications:', responseData.data);
        notifications = responseData.data;
      }catch(error){
        console.log('401 ERROR on /notifications');          
      }
    }
    return notifications;
  }

export async function checkIfEmailIsTaken(firebaseUser:FirebaseUserProps|undefined, email:String) : Promise<boolean>{

    if(!cpapApiEndpoint || !firebaseUser){
      return false;
    }
  
    console.log('Checking if email is taken:', email);
  
    let emailLowerCase = email.toLowerCase();
    const {uid, bearerToken } = firebaseUser;
    const filter = {'where':{'email':emailLowerCase } };
  
    const apiendpoint = `${cpapApiEndpoint}users/email/${emailLowerCase}`;
    setAxiosDefaultHeaders(uid, bearerToken);
    // try{
    const responseData = await axios.get(apiendpoint);
  
    console.log('Is email taken:', responseData);
    if(!responseData.data) return false;
    if(responseData.data.length === 0) return false;
  
    //this email is taken, meaning there's some emmauser entry that has this email
    //
    //what if the user has retyped their own email back?
    if (firebaseUser.uid == responseData.data[0].uid){
      console.log('firebaseuser uid:', firebaseUser.uid);
      console.log('found cpapuser:', responseData.data[0]);
      return false;
    }
  
    return true;
  
  }

// Used to set up the user that is logged in, so don't the subject is needed.
export async function getUserDataWithEmail(email:string, bearerToken:string, uid:string){
    if(!cpapApiEndpoint){
      return null;
    }
    const firebaseUser:FirebaseUserProps = {
      uid:uid,
      bearerToken:bearerToken,
      email:email,
      photoURL:'',
      displayName:email,
      firebaseUserObject:null
    };
    return getCpapUserWithFilter(firebaseUser, email);
  }
  


  // when users register with PHONE auth, firebaseUser object might not have email
  // but the user might still be in the database under emmauser collection
  export async function getUserDataWithUid (uid: string, bearerToken: string) {
  
    
    if(!cpapApiEndpoint){
      return null;
    }
    
    const firebaseUser:FirebaseUserProps = {
      uid:uid,
      bearerToken:bearerToken,
      email:'',
      photoURL:'',
      displayName:'',
      firebaseUserObject:null
    };
    return getCpapUserById(firebaseUser, uid);
  }


  export async function getParentFromEmail(firebaseUser: FirebaseUserProps, email:string){


    if(!cpapApiEndpoint){
      return null;
    }
    
    const {uid, bearerToken } = firebaseUser;
    
    const apiendpoint = `${cpapApiEndpoint}users/email/${email}`;
    setAxiosDefaultHeaders(uid, bearerToken);
    try{

      console.log('Hitting endpoint:', apiendpoint);
      const responseData = await axios.get(apiendpoint);
      console.log('Users get api response:', responseData);
      if(!responseData.data) return undefined;
      return responseData.data;

    } catch (error){
      console.log('ERROR on /users/:id', error);
    }
  }

  export async function getSubjectFromParentEmail (firebaseUser:FirebaseUserProps,email: string) {
  
    if(!cpapApiEndpoint){
      return null;
    }
    
    const {uid, bearerToken } = firebaseUser;
    
    const parent = await getParentFromEmail(firebaseUser, email);
    console.log('parent:', parent);
    if(!parent || parent.length == 0) return undefined;
    const parentid = parent[0]._id;

    const apiendpoint = `${cpapApiEndpoint}subjects/parent/${parentid}`;
    setAxiosDefaultHeaders(uid, bearerToken);
    try{

      console.log('Hitting endpoint:', apiendpoint);
      const responseData = await axios.get(apiendpoint);
      console.log('Subject get api response:', responseData.data);
      if(!responseData.data) return undefined;
      return responseData.data;

    } catch (error){
      console.log('ERROR on /subjects/:id', error);
    }

  }


export async function getAllCpapUsers(user:UserProps):Promise<any[]>{
    if(!cpapApiEndpoint){
      // might be good to have some data for this
      return [];
    }
  
    let rawUserData:any = [];
    if(!user.firebaseUser) return rawUserData;
    const {uid, bearerToken } = user.firebaseUser;
    setAxiosDefaultHeaders(uid, bearerToken);

  
    const userUrl = `${cpapApiEndpoint}users`;
  
    const usersResponse = await axios.get(userUrl);
    rawUserData = usersResponse.data;

    return rawUserData;
  }


  // check for presence of studyid in the StudyParticipant collection
  // return '' string if no studyid entry for this parent email
  export async function checkForStudyId(firebaseUser: FirebaseUserProps|undefined, email:String):
  Promise<string> {

    const studyid = '';
    if(!cpapApiEndpoint || !firebaseUser){
      return studyid;
    }
    if(email === undefined) { return studyid};
  
    let emailLowerCase = email.toLowerCase();
    const {uid, bearerToken } = firebaseUser;

    const apiendpoint = `${cpapApiEndpoint}study-participants/email/${emailLowerCase}`;
    setAxiosDefaultHeaders(uid, bearerToken);
  
    const responseData = await axios.get(apiendpoint);
  
    if(!responseData.data) return studyid;
    if(responseData.data.length === 0) return studyid;
  
    let participantData =  responseData.data[0];
  
    if (participantData?.studyid?.toLowerCase() !== ''){
      return participantData.studyid;
    }

    return studyid;

  }

  export async function checkIfStudyidValid(firebaseUser:FirebaseUserProps|undefined, email:String, studyid: String) : 
  Promise<boolean> {
  
    if(!cpapApiEndpoint || !firebaseUser){
      return false;
    }
    if(email === undefined) { return false};
  
    let emailLowerCase = email.toLowerCase();
    const {uid, bearerToken } = firebaseUser;
  
    if (studyid === undefined) {return false};
  
    let studyidLowerCase = studyid.toLowerCase();
  
    //const filter = { 'where': { 'and': [{'email': emailLowerCase } , {'studyid': studyidLowerCase }] } };
  
    const filter = {'where':{'email':emailLowerCase } };
    const apiendpoint = `${cpapApiEndpoint}/study-participants/email/${emailLowerCase}`;
    setAxiosDefaultHeaders(uid, bearerToken);
  
    const responseData = await axios.get(apiendpoint);
  
    if(!responseData.data) return false;
    if(responseData.data.length === 0) return false;
  
    let participantData =  responseData.data[0];
  
    if (participantData?.studyid?.toLowerCase() === studyidLowerCase){
      return true;
    }
  
  
    return false;
  }
  

