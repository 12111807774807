import React, {useEffect, useState} from 'react';
import { Button } from '@material-ui/core';
import MUIDataTable, {FilterType, SelectableRows} from 'mui-datatables';
import { useUserListState } from '../../../context/UserListContext';
import { UserProps } from '../../../common/entityUtilities';
import { useUserState } from '../../../context/UserContext';

export function UserInformationTable(props) {

  const initialColumns = [
    {
      name:'firstname',
      label:'First Name',
      options: { }
    },
    {
      name:'lastname',
      label:'Last Name',
      options: { }
    },    
    {
      name:'localEmail',
      label:'Email',
      options:{}
    },  
    {
      name:'authLevel',
      label:'Status',
      options:{
        display:false,
        customFilterListOptions: { render: v => `Status: ${v}` },
      }

    },
  ];
  const {editOrCreateUser, initialAuthLevelFilter} = props;
  const activeUser = useUserState();
  const { allUsers } = useUserListState();
  const [tableData, setTableData] = useState([]);
  const [columns, setColumns] = useState(initialColumns);

  const rowClick = (rowData, rowMeta) => {
    console.log(rowData, 'Rwo clic', rowMeta);
    editOrCreateUser(tableData[rowMeta.dataIndex]); //rowIndex is for visible index
  };

  const options = {
    filterType: 'dropdown',
    onFilterChange: (changedColumn, filterList) => {
      console.log(changedColumn, filterList);
    },
    download:false,
    filter:true,
    print:false,
    search:false,
    selectableRows:'none',
    onRowClick:rowClick,
    // eslint-disable-next-line react/no-multi-comp
    customToolbar: () => {
      return (
        <Button 
          color="primary"
          onClick={()=> {editOrCreateUser(null);}}
          variant="outlined"
        >
          Add user
        </Button>
        // <CustomToolbar />
      );
    }
  };

  useEffect(() =>{
    console.log('Setting up all users:', allUsers);
    let toDisplay = allUsers;
    const currentAuthLevel = activeUser.authLevel;
    if(currentAuthLevel <=0){
      // hopefully this is never undefined
      toDisplay = allUsers.filter(user=>user.userId === activeUser.userId);
    }else if(currentAuthLevel <=5){
      // this will get non registered users too. When a proper family structure gets setup
      // this should not be a problem.
      toDisplay = allUsers.filter(user=>user.authLevel<5);
    }
    setTableData(toDisplay);
  }, [allUsers]);

  useEffect(() => {
    if(initialAuthLevelFilter !== undefined){
      setColumns(
        [
          {
            name:'firstname',
            label:'First Name',
            options: { }
          },
          {
            name:'lastname',
            label:'Last Name',
            options: { }
          },    
          {
            name:'localEmail',
            label:'Email',
            options:{}
          },  
          {
            name:'authLevel',
            label:'Status',
            options:{
              display:false,
              filterList:[initialAuthLevelFilter],
              customFilterListOptions: { render: v => `Status: ${v}` },
            }
          },
        ]);
    }
  }, [initialAuthLevelFilter]);

  return(
    <MUIDataTable
      columns={columns}
      data={tableData}
      options={options}
      title={'User List'}
    />
  );
}